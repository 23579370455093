<section class="generic-dialog">
<header>
  <h1 mat-dialog-title>{{title}}</h1>
</header>
<div mat-dialog-content>
  <p class="{{type}} pre-wrap" >{{message}}</p>
  <p>{{description}}</p>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button *ngIf="isChoiceBased()" mat-button mat-dialog-close [mat-dialog-close]="false" class="grey">{{'policies.editor.cancel'|translate}}</button>
  <button mat-button mat-dialog-close class="confirm-action-btn" [mat-dialog-close]="true">OK</button>
</div>
</section>
