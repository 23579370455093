import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {UploadErrorsComponent} from './upload-errors/upload-errors.component';


const routes: Routes = [
  {
    path: '',
    component: UploadErrorsComponent
  },
  {
    path: '', redirectTo: '', pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UploadErrorsRouting {
}
