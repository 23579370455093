<section class="main-content">

  <h1>{{'finraSettings.finraSettings' | translate}}</h1>
  <mat-card>

    <div class="spinner-container" *ngIf="loading$ | async">
      <mat-spinner></mat-spinner>
    </div>

    <section [hidden]="loading$ | async">
      <section class="actions-container" fxLayout="row" fxLayoutAlign="space-between center">
        <h2>{{'finraSettings.cardTitleSaveConfigurationFinra' | translate}}</h2>
        <section fxLayout="row" *ngIf="isSuperAdmin()" >
          <button mat-button class="btn-background-blue fw-500" (click)="save()" [disabled]="!this.settingsFormGroup.valid">
            {{'finraSettings.buttons.save' | translate}}
          </button>
        </section>
      </section>

      <p class="grey-label label-margin">{{'finraSettings.cardSubTitleCardTitleSaveConfigurationFinra' | translate}}</p>

      <form class="actions-container" [formGroup]="settingsFormGroup"  [hidden]="loading$ | async">

        <section fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout="column"  fxFlex="32">
            <mat-form-field class="full-width" appearance="fill" >
              <mat-label>{{'finraSettings.form.fieldLabels.bucket' | translate}}</mat-label>
              <input matInput formControlName="bucketName" autocomplete="off">
            </mat-form-field>
          </div>

          <div fxLayout="column"  fxFlex="32">
            <mat-form-field class="full-width" appearance="fill" >
              <mat-label>{{'finraSettings.form.fieldLabels.referenceCatalogId' | translate}}</mat-label>
              <input matInput formControlName="referenceCatalogId" autocomplete="off">
            </mat-form-field>
          </div>

          <div fxLayout="column" fxFlex="32">
            <mat-form-field class="full-width" appearance="fill"  >
              <mat-label>{{'finraSettings.form.fieldLabels.driveLabelId' | translate}}</mat-label>
              <input matInput formControlName="driveLabelId" autocomplete="off">
            </mat-form-field>
          </div>
        </section>

        <section fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout="column"  fxFlex="32">
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>{{'finraSettings.form.fieldLabels.driveLabelFinalizedFieldId' | translate}}</mat-label>
              <input matInput formControlName="driveLabelFinalizedStatusFieldId" autocomplete="off">
            </mat-form-field>
          </div>

          <div fxLayout="column"  fxFlex="32">
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>{{'finraSettings.form.fieldLabels.driveLabelFinalizedStatusFieldValueId' | translate}}</mat-label>
              <input matInput formControlName="driveLabelFinalizedStatusFieldValueId" autocomplete="off">
            </mat-form-field>
          </div>

          <div fxLayout="column"  fxFlex="32">
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>{{'finraSettings.form.fieldLabels.driveLabelFinalizedAndExportedStatusFieldValueId' | translate}}</mat-label>
              <input matInput formControlName="driveLabelFinalizedAndExportedStatusFieldValueId" autocomplete="off">
            </mat-form-field>
          </div>
        </section>

      </form>
    </section>
  </mat-card>

  <mat-card class="card-margin-top" *ngIf="applyConfigurationCard()">
    <h2>{{'finraSettings.cardTitleApplyConfigurationFinra' | translate}}</h2>
    <p class="grey-label">{{'finraSettings.cardSubTitleApplyConfigurationFinra' | translate}}</p>

    <form [formGroup]="applySettingsFormGroup" fxLayout="row" fxLayoutAlign="space-between center">
      <mat-form-field class="library-search" appearance="fill" fxFlex="32">
        <mat-label>{{'finraSettings.finraSettingsLibrarySearch' | translate}}</mat-label>
        <input type="text" matInput formControlName="library"  [matAutocomplete]="auto" >
        <mat-icon matSuffix>search</mat-icon>

        <mat-autocomplete #auto="matAutocomplete"[displayWith]="displayFn">
          <mat-option *ngFor="let library of libraries"
                      [value]="library">
            {{library.displayName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <button mat-button class="btn-background-blue fw-500" (click)="applyFinraSettings()" [disabled]="this.applyConfigurationButtonDisabled">
        {{'finraSettings.form.applyButton' | translate}}
      </button>
    </form>
  </mat-card>

</section>
