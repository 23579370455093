import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-activation-threshold',
  templateUrl: './activation-threshold.component.html',
  styleUrls: ['./activation-threshold.component.scss']
})
export class ActivationThresholdComponent implements OnInit {

  @Input() list: string[];

  constructor() {
  }

  ngOnInit(): void {
  }

}
