import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {DocumentService} from '../../../services/document.service';
import {DialogService} from '../../../services/dialog.service';
import {UnlockedDocument} from '../../../models/unlocked-document.model';
import {MatTableDataSource} from '@angular/material/table';
import {BehaviorSubject, Subject} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {CustomSnackBarService} from '../../../services/custom-snackbar.service';
import {TranslateService} from '@ngx-translate/core';
import {
  LIST_UNLOCKED_DOCS_TIMEOUT,
  UNLOCK_DOC_NOT_IN_CONTROL_STATE_MESSAGE,
  UNLOCK_TEAM_FOLDER_SERVER_MESSAGE
} from '../../../constants/lock-management-constant';


@Component({
  selector: 'app-lock-management',
  templateUrl: './lock-management.component.html',
  styleUrls: ['./lock-management.component.scss']
})
export class LockManagementComponent implements OnInit, AfterViewInit, OnDestroy {

  public unlockDocumentForm = new FormControl();
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public searchValueSubject: Subject<string> = new Subject<string>();
  public searchClearSubject: Subject<boolean> = new Subject();
  public dataSource: MatTableDataSource<UnlockedDocument>;
  public columns = ['documentId', 'documentName', 'library', 'edit', 'lock'];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private documentService: DocumentService,
              private dialogService: DialogService,
              private customSnackBarService: CustomSnackBarService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();

    this.listUnlockedDocuments();
    this.searchInit();
  }

  ngOnDestroy(): void {
    this.searchValueSubject.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  searchInit(): void {
    this.searchValueSubject.subscribe((value) => {
      this.dataSource.filter = value.trim().toLowerCase();
    });
  }

  clearValue(): void {
    this.unlockDocumentForm.reset();
  }

  isDisabled(): boolean {
    return !this.unlockDocumentForm.value;
  }

  unlock(): void {
    this.documentService.unlockDocument(this.unlockDocumentForm.value).subscribe({
      next: () => {
        this.customSnackBarService.openSuccessSnackbar(this.translate.instant('snackBarMessage.unlockLockDocuments.unlockDocuments'));
        this.unlockDocumentForm.reset();
        setTimeout(() => this.listUnlockedDocuments(), LIST_UNLOCKED_DOCS_TIMEOUT);
      },
      error: (error) => {
        if (error.code === 400 && (error?.error?.error?.message ?? error.message.toUpperCase()) === UNLOCK_TEAM_FOLDER_SERVER_MESSAGE.toUpperCase()) {
          this.dialogService.info(this.translate.instant('unlockLockDocuments.unlockDocuments.unlockTeamFolderAlertMessage'));
        }
        if (error.code === 400 && (error?.error?.error?.message ?? error.message.toUpperCase()) === UNLOCK_DOC_NOT_IN_CONTROL_STATE_MESSAGE.toUpperCase()) {
          this.dialogService.info(this.translate.instant('unlockLockDocuments.unlockDocuments.unlockDocNotInControlStateMessage'));
        } else {
          this.dialogService.error(error?.error?.error?.message ?? error.message);
        }
      }
    });
  }

  lock(unlockedDocument: UnlockedDocument): void {
    this.documentService.lockDocument(unlockedDocument.documentId).subscribe({
      next: () => {
        this.customSnackBarService.openSuccessSnackbar(this.translate.instant('snackBarMessage.unlockLockDocuments.lockDocuments'));
        this.unlockDocumentForm.reset();
        this.listUnlockedDocuments();
      },
      error: error => this.dialogService.error(error?.error?.error?.message ?? error.message)
    });
  }

  displayedColumns(): string[] {
    return this.columns;
  }

  listUnlockedDocuments(): void {
    this.loadingSubject.next(true);
    this.documentService.listUnlockedDocument().subscribe({
      next: (resp) => {
        this.dataSource.data = resp.items;
        this.loadingSubject.next(false);
      },
      error: (error) => {
        this.loadingSubject.next(false);
        this.dialogService.error(error?.error?.error?.message ?? error.message);
      }
    });
  }

  refresh(): void {
    this.listUnlockedDocuments();
  }

  getLibrarySettingsUrl(unlockedDocument: UnlockedDocument): string {
    return this.documentService.getAodocsDocumentUrl(unlockedDocument);
  }

}
