import {MatDialogRef} from '@angular/material/dialog';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {ChangeDetectorRef} from '@angular/core';
import {RetentionService} from '../../services/retention.service';
import {DialogService} from '../../services/dialog.service';
import {MatRadioChange} from '@angular/material/radio';
import {RetentionMethod} from '../../constants/retention-constants';
import {BehaviorSubject} from 'rxjs';
import {Policy} from '../../models/retention.model';
import {BothOrNoneValidator} from '../../validators/custom-validators';
import {TranslateService} from '@ngx-translate/core';


export abstract class PoliciesBaseComponent {

  public policy: Policy;

  public identificationFormGroup: FormGroup;
  public setupFormGroup: FormGroup;
  public optionsFormGroup: FormGroup;

  public retentionMethod: typeof RetentionMethod = RetentionMethod;
  public loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  protected isFinra = false;

  protected constructor(protected dialogRef: MatDialogRef<PoliciesBaseComponent>,
                        protected formBuilder: FormBuilder,
                        protected cdr: ChangeDetectorRef,
                        protected retentionService: RetentionService,
                        protected dialogService: DialogService,
                        protected translate: TranslateService) {
  }

  methodChanged(event: MatRadioChange): void {
    switch (event.value) {
      case RetentionMethod.CREATION_DATE:
      case RetentionMethod.MODIFICATION_DATE:
        this.creationDateSelected(event.value);
        return;
      case RetentionMethod.NOT_CLASSIFIED:
        this.notClassifiedSelected(event.value);
        return;
      case RetentionMethod.CUSTOM_EVENT:
        this.customEventSelected();
        return;
    }
  }

  protected creationDateSelected(method: string): void {
    this.enableFormControl('offset');
    this.disableFormControl('event');
    this.setFormControlValue('event', method);
    this.setFormControlValueCustomOffset('offset', null);
  }

  protected notClassifiedSelected(method: string): void {
    this.disableFormControl('event');
    this.disableFormControl('offset');
    this.setFormControlValue('offset', 1);
    this.setFormControlValue('event', method);
  }

  protected customEventSelected(): void {
    this.enableFormControl('event');
    this.disableFormControl('offset');
    this.setFormControlValueCustomEvent('event', '');
    this.setFormControlValue('offset', null);
  }

  setFormControlValue(formControl: string, value: any): void {
    this.setupFormGroup.get(formControl).setValue(value);
  }

  setFormControlValueCustomEvent(formControl: string, value: any): void {
    this.setupFormGroup.get(formControl).setValue(value);
  }

  setFormControlValueCustomOffset(formControl: string, value: any): void {
    this.setupFormGroup.get(formControl).setValue(value);
  }

  enableFormControl(formControl: string): void {
    this.setupFormGroup.get(formControl).enable();
  }

  disableFormControl(formControl: string): void {
    this.setupFormGroup.get(formControl).disable();
  }

  offsetToolTip(): string {
    return this.translate.instant('policies.tooltip');
  }

  get metadata(): FormArray {
    return this.optionsFormGroup.get('metadata') as FormArray;
  }

  addMetadata(): void {
    this.metadata.push(
      this.formBuilder.group({
        property: [''],
        value: ['']
      }, {validator: BothOrNoneValidator()})
    );
  }

  removeMetadata(index: number): void {
    if (this.metadata.length === 1) {
      return;
    }
    this.metadata.removeAt(index);
    this.cdr.detectChanges();
  }

  policyToSave(): Policy {
    const identification = this.identificationFormGroup.getRawValue();
    const setup = this.setupFormGroup.getRawValue();
    const options = this.optionsFormGroup.getRawValue();
    options.metadata = options.metadata.filter(n => n.property !== '');
    return {
      id: !!this.policy ? this.policy.id : undefined,
      active: options.active,
      code: identification.code,
      country: identification.country,
      customOffset: setup.offset,
      description: identification.description,
      event: setup.event,
      eventLabel: setup.secondaryLabel,
      function: identification.function,
      metadataConstraint: options.metadata.reduce((acc, {property, value}) => ({...acc, [property]: value}), {}),
      recordCategory: identification.category,
      retentionTime: setup.time,
      subFunction: identification.subFunction,
      finra: this.isFinra
    };
  }

  confirmCreate(): void {
    const policy = this.policyToSave();
    this.loadingSubject.next(true);
    this.retentionService.create(policy).subscribe({
      next: () => {
        this.loadingSubject.next(false);
        this.dialogRef.close(true);
      },
      error: (error) => {
        this.loadingSubject.next(false);
        this.dialogService.error(error?.error?.error?.message ?? error.message);
      }
    });
  }

}

