import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {ApiVersion} from '../constants/apis';
import {LegalHold} from '../models/legal-hold';
import {LegalHoldRulesType} from '../constants/legal-hold-constants';
import {DatePipe} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LegalHoldService extends AbstractService {

  constructor(private datePipe: DatePipe, private http: HttpClient) {
    super();
  }

  list(): Observable<{ items: LegalHold[] }> {
    const url = this.toUrl('legalHold', 'list', ApiVersion.V1);
    return this.http.get<{ items: LegalHold[] }>(url);
  }

  create(legalHold: LegalHold): Observable<void> {
    const url = this.toUrl('legalHold', 'create', ApiVersion.V1);
    return this.http.post<void>(url, legalHold);
  }

  release(legalHoldId: string): Observable<void> {
    const url = this.toUrl('legalHold', 'release/' + legalHoldId, ApiVersion.V1);
    return this.http.get<void>(url);
  }

  convertRules(rulesMap: any): string {
    let rules = '';
    Object.keys(rulesMap).forEach(k => {
      switch (k) {
        case LegalHoldRulesType.LIBRARY_NAME:
          rules = rules + 'Library name:\xa0' + rulesMap[LegalHoldRulesType.LIBRARY_NAME] + ',\xa0\xa0';
          break;
        case LegalHoldRulesType.CLASS_NAME:
          rules = rules + 'Class name:\xa0' + rulesMap[LegalHoldRulesType.CLASS_NAME] + ',\xa0\xa0';
          break;
        case LegalHoldRulesType.INITIAL_AUTHOR:
          rules = rules + 'Initial author:\xa0' + rulesMap[LegalHoldRulesType.INITIAL_AUTHOR] + ',\xa0\xa0';
          break;
        case LegalHoldRulesType.UPDATE_AUTHOR:
          rules = rules + 'Update author:\xa0' + rulesMap[LegalHoldRulesType.UPDATE_AUTHOR] + ',\xa0\xa0';
          break;
        case LegalHoldRulesType.TITLE:
          rules = rules + 'Title:\xa0' + rulesMap[LegalHoldRulesType.TITLE] + ',\xa0\xa0';
          break;
        case LegalHoldRulesType.CREATION_DATE:
          const range = rulesMap[LegalHoldRulesType.CREATION_DATE].split('_');
          const from = this.datePipe.transform(new Date(Number(range[0])), 'dd/MM/yyyy');
          const to = this.datePipe.transform(new Date(Number(range[1])), 'dd/MM/yyyy');
          rules = rules + 'Creation date:\xa0' + from + '-' + to + ',\xa0\xa0';
          break;
        case LegalHoldRulesType.MODIFICATION_DATE:
          const rangeModif = rulesMap[LegalHoldRulesType.MODIFICATION_DATE].split('_');
          const fromModif = this.datePipe.transform(new Date(Number(rangeModif[0])), 'dd/MM/yyyy');
          const toModif = this.datePipe.transform(new Date(Number(rangeModif[1])), 'dd/MM/yyyy');
          rules = rules + 'Modification date date:\xa0' + fromModif + '-' + toModif + ',\xa0\xa0';
          break;
      }
    });

    return rules;
  }
}

