import {Injectable, NgZone} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {AuthService} from '../auth.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(public authService: AuthService,
              public router: Router,
              public ngZone: NgZone) {
  }

  canActivate(): Observable<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authService.isLoggedIn()) {
      this.ngZone.run(() => {
        this.router.navigate(['login']);
      });
    } else {
      return true;
    }
  }
}
