import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {BlankPageComponent} from './components/blank-page/blank-page.component';
import {AuthGuardService} from './services/guard/auth-guard.service';
import {RouteGuardService} from './services/guard/route-guard.service';

const routes: Routes = [
  {
    path: 'libraries',
    loadChildren: () => import('./modules/libraries/libraries.module').then(m => m.LibrariesModule),
    canActivate: [AuthGuardService, RouteGuardService]
  }, {
    path: 'policies',
    loadChildren: () => import('./modules/policies/policies.module').then(m => m.PoliciesModule),
    canActivate: [AuthGuardService, RouteGuardService]
  }, {
    path: 'reports',
    loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuardService, RouteGuardService]
  }, {
    path: 'audit',
    loadChildren: () => import('./modules/audit/audit.module').then(m => m.AuditModule),
    canActivate: [AuthGuardService, RouteGuardService]
  }, {
    path: 'upload',
    loadChildren: () => import('./modules/upload-errors/upload-errors.module').then(m => m.UploadErrorsModule),
    canActivate: [AuthGuardService, RouteGuardService]
  }, {
    path: 'settings',
    loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),

    canActivate: [AuthGuardService, RouteGuardService]
  }, {
    path: 'holds',
    loadChildren: () => import('./modules/holds/holds.module').then(m => m.HoldsModule),
    canActivate: [AuthGuardService, RouteGuardService],
    canActivateChild: [RouteGuardService]
  },
  {
    path: 'login',
    component: BlankPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
