import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export abstract class AbstractService {

  protected apiUrl = environment.apiUrl;
  protected baseUrl = environment.baseUrl;

  protected constructor() {
  }

  protected toUrl(root: string, method: string, version: string): string {
    return this.baseUrl + this.apiUrl + root + (method ? '/' + version + '/' + method : '/' + version);
  }
}
