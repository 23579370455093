import {Component, Inject, OnInit} from '@angular/core';
import {Policy} from '../../../models/retention.model';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-policy-viewer',
  templateUrl: './policies-viewer.component.html',
  styleUrls: ['./policies-viewer.component.scss']
})
export class PoliciesViewerComponent implements OnInit {


  public policy: Policy;
  public isSuperAdmin: boolean;
  public metadataArray = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: { policy: Policy, isSuperAdmin: boolean }) {
    this.policy = data.policy;
    this.isSuperAdmin = data.isSuperAdmin;
    if (this.policy.metadataConstraint) {
      Object.keys(this.policy.metadataConstraint).forEach(key => this.metadataArray.push({
        property: key,
        value: this.policy.metadataConstraint[key]
      }));
    }
    console.log(this.metadataArray);
  }

  ngOnInit(): void {
  }

  edit(): void {
  }
}
