import {Component, Input, OnInit} from '@angular/core';
import {RetentionService} from '../../services/retention.service';
import {DialogService} from '../../services/dialog.service';
import {Folder} from '../../models/folder.model';
import {FolderService} from '../../services/folder.service';
import {Map} from 'core-js/es';
import {BehaviorSubject, Subject} from 'rxjs';

@Component({
  selector: 'app-folder-picker',
  templateUrl: './folder-picker.component.html',
  styleUrls: ['./folder-picker.component.scss']
})
export class FolderPickerComponent implements OnInit {

  @Input() rootFolderId: string;
  @Input() selectedSubject: Subject<{ folder: Folder, path: string }>;
  public currentFolder: Folder;
  public currentFolderChildren: Folder[] = [];
  private navigatedNodes = new Map<string, { data: Folder, isRoot: boolean, parent: Folder }>();
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public path: Folder[] = [];

  constructor(private retentionService: RetentionService,
              private dialogService: DialogService,
              private folderService: FolderService) {
  }

  ngOnInit(): void {
    this.getFolder(true);
  }


  getFolder(isRoot?: boolean): void {
    this.loadingSubject.next(true);
    const folderId = !isRoot ? this.currentFolder.id : this.rootFolderId;
    this.folderService.path(folderId).subscribe({
      next: (resp) => {
        this.loadingSubject.next(false);
        this.currentFolder = resp.items[0];
        this.addToNodes(this.currentFolder, isRoot);
        this.getFolderChildren();
        if (isRoot) {
          this.path.push(this.currentFolder);
        }
      },
      error: (error) => {
        this.loadingSubject.next(false);
        this.dialogService.error(error?.error?.error?.message ?? error.message);
      }
    });
  }

  getFolderChildren(): void {
    this.selectedSubject.next({folder: this.currentFolder, path: this.getPath()});
    this.loadingSubject.next(true);
    this.folderService.children(this.currentFolder.id).subscribe({
      next: (resp) => {
        this.loadingSubject.next(false);
        this.currentFolderChildren = resp.items;
      },
      error: (error) => {
        this.loadingSubject.next(false);
        this.dialogService.error(error?.error?.error?.message ?? error.message);
      }
    });
  }

  addToNodes(folder: Folder, isRoot?: boolean): void {
    if (!this.navigatedNodes.has(folder.id)) {
      const parent = {...this.currentFolder};
      this.navigatedNodes.set(folder.id, {data: folder, isRoot, parent});
    }
  }

  navigate(folderId?: string): void {
    if (folderId) {
      const selected = this.currentFolderChildren.filter((item) => item.id === folderId)[0];
      this.addToNodes(selected);
      this.currentFolder = {...selected};
      this.path.push(this.currentFolder);
      this.getFolderChildren();
    } else {
      const to = this.navigatedNodes.get(this.currentFolder.id);
      this.currentFolder = to.parent;
      if (!to.isRoot) {
        this.path.pop();
        this.getFolderChildren();
      }
    }
  }

  gotoPath(selected: Folder): void {
    this.currentFolder = {...selected};
    const idx = this.path.indexOf(selected);
    this.path = this.path.slice(0, idx + 1);
    this.getFolderChildren();
  }

  getPath(): string {
    let pathToString = '';
    this.path.forEach((item) => pathToString = pathToString.concat(item.name).concat(' > '));
    return pathToString.slice(0, pathToString.length - 2);
  }
}
