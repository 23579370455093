<section class="main-content">
  <h1>{{'title.audit' | translate}}</h1>
  <mat-card class="no-padding">
    <section class="audit-filter-section" fxLayout="row" fxLayoutAlign="start center">
      <mat-form-field class="input audit-filter" appearance="fill">
        <mat-label>Type</mat-label>
        <mat-select [formControl]="typeFilter">
          <mat-option></mat-option>
          <mat-option [value]="type" *ngFor="let type of auditTypes">
            {{  type  }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="input date-range audit-filter" appearance="fill">
        <mat-label>Date</mat-label>
        <mat-date-range-input [formGroup]="dateRangeFormGroup" [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="Start date">
          <input matEndDate formControlName="end" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

      <mat-form-field class="input audit-filter" appearance="fill">
        <mat-label>Author</mat-label>
        <mat-select [formControl]="authorFilter">
          <mat-option></mat-option>
          <mat-option [value]="author" *ngFor="let author of auditAuthors">
            {{  author  }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="input audit-filter" appearance="fill">
        <mat-label>Library</mat-label>
        <mat-select [formControl]="libraryFilter">
          <mat-option></mat-option>
          <mat-option [value]="lib" *ngFor="let lib of auditLibraries">
            {{  lib  }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="input audit-filter" appearance="fill">
        <mat-label>Class</mat-label>
        <mat-select [formControl]="classFilter">
          <mat-option></mat-option>
          <mat-option [value]="clazz" *ngFor="let clazz of auditClasses">
            {{  clazz  }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="input audit-filter" appearance="fill">
        <mat-label>Document</mat-label>
        <mat-select [formControl]="documentFilter">
          <mat-option></mat-option>
          <mat-option [value]="doc" *ngFor="let doc of auditDocuments">
            {{  doc  }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="input audit-filter" appearance="fill">
        <mat-label>Log Level</mat-label>
        <mat-select [formControl]="logLevelFilter">
          <mat-option></mat-option>
          <mat-option [value]="doc" *ngFor="let doc of logLevelFilterList">
            {{  doc  }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-icon-button (click)="search()" class="action-icon"
              matTooltip="{{'audit.list.table.tooltips.search' | translate}}">
        <mat-icon>search</mat-icon>
      </button>

      <section class="refresh-container" fxLayoutAlign="end start">
        <button mat-icon-button (click)="refresh()" class="action-icon"
                matTooltip="{{'audit.list.table.tooltips.refresh' | translate}}">
          <mat-icon>refresh</mat-icon>
        </button>
      </section>
    </section>

    <div class="spinner-container" *ngIf="dataSource.loading$ | async">
      <mat-spinner></mat-spinner>
    </div>

    <section fxLayout="row" fxLayoutAlign="start">
      <table mat-table [dataSource]="dataSource" id="audit-log-table" class="mat-elevation-z8"
             [hidden]="dataSource.loading$ | async">

        <!-- eventType -->
        <ng-container matColumnDef="eventType">
          <mat-header-cell *matHeaderCellDef><span>{{'audit.list.table.eventType' | translate}}</span></mat-header-cell>
          <mat-cell *matCellDef="let element"><span [matTooltip]="element.message"> {{element.eventType}} </span>
          </mat-cell>
        </ng-container>

        <!-- eventDate -->
        <ng-container matColumnDef="eventDate">
          <mat-header-cell *matHeaderCellDef><span>{{'audit.list.table.eventDate' | translate}}</span></mat-header-cell>
          <mat-cell *matCellDef="let element"><span
            [matTooltip]="element.eventDate"> {{element.eventDate | date: 'dd/MM/yyyy, h:mm:ss a'}} </span>
          </mat-cell>
        </ng-container>

        <!-- logLevel -->
        <ng-container matColumnDef="logLevel">
          <mat-header-cell *matHeaderCellDef><span>{{'audit.list.table.logLevel' | translate}}</span></mat-header-cell>
          <mat-cell
            [ngClass]="{'log-level-info': element.logLevel === 'INFO', 'log-level-warning' : element.logLevel === 'WARNING', 'log-level-severe' : element.logLevel === 'SEVERE'}"
            *matCellDef="let element"><span [matTooltip]="element.logLevel"> {{element.logLevel}} </span>
          </mat-cell>
        </ng-container>

        <!-- library -->
        <ng-container matColumnDef="library">
          <mat-header-cell *matHeaderCellDef>{{'audit.list.table.library' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element"><span [matTooltip]="element.library">  {{element.library}}</span>
          </mat-cell>
        </ng-container>

        <!-- class -->
        <ng-container matColumnDef="documentClass">
          <mat-header-cell *matHeaderCellDef><span>{{'audit.list.table.class' | translate}}</span></mat-header-cell>
          <mat-cell *matCellDef="let element"><span
            [matTooltip]="element.documentClass">  {{element.documentClass}}</span></mat-cell>
        </ng-container>

        <!-- document -->
        <ng-container matColumnDef="document">
          <mat-header-cell *matHeaderCellDef><span>{{'audit.list.table.document' | translate}}</span></mat-header-cell>
          <mat-cell *matCellDef="let element"><span [matTooltip]="element.document">  {{element.document}}</span>
          </mat-cell>
        </ng-container>

        <!-- author -->
        <ng-container matColumnDef="author">
          <mat-header-cell *matHeaderCellDef><span>{{'audit.list.table.author' | translate}}</span></mat-header-cell>
          <mat-cell *matCellDef="let element"><span [matTooltip]="element.author"> {{element.author}}</span>
          </mat-cell>
        </ng-container>

        <!-- view -->
        <ng-container matColumnDef="view">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-button mat-icon-button (click)="view(element)"
                    class="table-button">
              <mat-icon>visibility</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns()"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns();"></mat-row>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">{{"dataNotFound" | translate}}</td>
        </tr>
      </table>
    </section>
    <section [hidden]="dataSource.loading$ | async">
      <mat-paginator [length]="auditLogsCount" [pageSize]="getPageSize()" [showFirstLastButtons]="false" [pageSizeOptions]="[10,20,30]"></mat-paginator>
    </section>
  </mat-card>
</section>
