<div id="spinner-container" class="spinner-container" *ngIf="loading">
  <mat-spinner [diameter]="25"></mat-spinner>
</div>
<section *ngIf="domain" fxLayout="row" fxHide.xs>
  <mat-icon class="domain-header-logo">business</mat-icon>
  <div class="domain-header">{{domain}}</div>
</section>
<section *ngIf="domain" fxHide fxShow.xs>
  <mat-icon class="domain-header-logo" [matMenuTriggerFor]="appMenu">business</mat-icon>
  <mat-menu #appMenu>
    <p class="domain-header-xs">{{domain}}</p>
  </mat-menu>
</section>
