<button mat-icon-button class="close-button" [mat-dialog-close]="false">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h2 mat-dialog-title class="header">{{"legalHold.releaser.title" | translate}} : {{legalHold.id}}</h2>
<mat-dialog-content id="release-dialog-content" class="mat-typography">
  <div class="spinner-container" *ngIf="loading$ | async">
    <mat-spinner></mat-spinner>
  </div>
  <section [hidden]="loading$ | async">
    <section class="banner">
      <h2>{{documents()}} {{"legalHold.releaser.documents" | translate}}<span
        class="grey"> {{"legalHold.releaser.inHold" | translate}}</span></h2>
    </section>
    <section class="releaser-body">
      <p class="grey-label label-margin">{{"legalHold.releaser.label"|translate}}</p>
      <div fxLayout="column" fxLayoutAlign="space-between start" class="rules-container">
        <div fxLayout="row" fxLayoutAlign="space-between start" *ngFor="let rule of rules" class="rule-holder">
          <mat-form-field appearance="fill" fxFlex="48">
            <input matInput [readonly]="true" [value]="rule.property">
          </mat-form-field>
          <mat-form-field appearance="fill" fxFlex="48">
            <input matInput [readonly]="true" [value]="rule.value">
          </mat-form-field>
        </div>
      </div>
      <section mat-dialog-actions align="end" class="creator-footer">
        <section fxFlex fxLayout="row" fxLayoutAlign="end center">
          <button mat-button [mat-dialog-close]="false">
            {{"legalHold.creator.cancel"| translate}}
          </button>
          <button mat-button class="create-btn" (click)="release()" [disabled]="legalHold.released"
                  *ngIf="!isAuditor()">
            {{"legalHold.releaser.release"|translate}}</button>
        </section>
      </section>
    </section>
  </section>
</mat-dialog-content>
