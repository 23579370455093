import {Injectable} from '@angular/core';
import {ApiVersion} from '../constants/apis';
import {AbstractService} from './abstract.service';
import {Category, PurgeDomainJob, PurgeLibraryJob, ReportDetails} from '../models/reporting.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportingService extends AbstractService {

  constructor(private http: HttpClient) {
    super();
  }

  loadWarnNotificationReportDetails(): Observable<ReportDetails> {
    const url = this.toUrl('reporting', 'get/warningnotificationreportdetails', ApiVersion.V1);
    return this.http.get<ReportDetails>(url);
  }

  exportRetentionBindings(retentionCode: string): Observable<void> {
    const url = this.toUrl('reporting', 'export/retentionbindings', ApiVersion.V1);
    const params = new HttpParams()
      .set('retentionCode', retentionCode);
    return this.http.get<void>(url, {params});
  }

  listRecordCategories(libraryId: string): Observable<{ items: Category[] }> {
    const url = this.toUrl('reporting', 'list/recordcategories', ApiVersion.V1);
    const params = new HttpParams()
      .set('libraryId', libraryId);
    return this.http.get<{ items: Category[] }>(url, {params});
  }

  exportDocsToBeDeleted(targetDestrStartDate: string,
                        targetDestrEndDate: string,
                        recordCategoryId: string,
                        libraryId: string): Observable<void> {
    const url = this.toUrl('reporting', 'export/docstobedeleted', ApiVersion.V1);
    const params = {targetDestrStartDate, targetDestrEndDate, recordCategoryId, libraryId};
    return this.http.post<void>(url, params);
  }

  listDomainPurgeJobs(): Observable<{ items: PurgeDomainJob[] }> {
    const url = this.toUrl('reporting', 'list/domainpurgejobs', ApiVersion.V1);
    return this.http.get<{ items: PurgeDomainJob[] }>(url);
  }

  listLibraryPurgeJobsUnderDomainPurgeJob(domainJobId: string): Observable<{ items: PurgeLibraryJob[] }> {
    const url = this.toUrl('reporting', 'list/domainpurgejobs/' + domainJobId, ApiVersion.V1);
    return this.http.get<{ items: PurgeLibraryJob[] }>(url);
  }

  exportDeletedDocsForLibraryJob(domainJobId: string, libraryJobId: string): Observable<void> {
    const url = this.toUrl('reporting', 'export/deleted/' + domainJobId + '/' + libraryJobId, ApiVersion.V1);
    return this.http.get<void>(url);
  }

  exportDeletedDocsForDomainJob(domainJobId: string): Observable<void> {
    const url = this.toUrl('reporting', 'export/deleteddocsfordomainjob', ApiVersion.V1);
    const params = new HttpParams()
      .set('domainJobId', domainJobId);
    return this.http.get<void>(url, {params});
  }

}
