import {Component, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {DocumentClassState} from '../../../models/library.model';

@Component({
  selector: 'app-library-configuration-error',
  templateUrl: './configuration-error.component.html',
  styleUrls: ['./configuration-error.component.scss'],
  animations: [
    trigger('tableExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ConfigurationErrorComponent implements OnInit {

  @Input() isNotReady: boolean;
  @Input() isWithError: boolean;
  @Input() dataSource: DocumentClassState[];
  @Input() libraryId: string;
  public expanded = false;

  constructor(private iconRegistry: MatIconRegistry, private  sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon('lib_not_ready', sanitizer.bypassSecurityTrustResourceUrl('assets/img/lib_not_ready.svg'))
                .addSvgIcon('lib_error', sanitizer.bypassSecurityTrustResourceUrl('assets/img/lib_error.svg'));
  }

  ngOnInit(): void {
  }

  toggleExpand(): void {
    this.expanded = !this.expanded;
  }

}
