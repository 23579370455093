<button mat-icon-button class="close-button" [mat-dialog-close]="false">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<section id="retained-document-attachments-dialog">
  <h2 mat-dialog-title class="header">Document attachments</h2>
  <section class="title-banner" fxLayout="column" fxLayoutAlign="start">
    <p class="grey-label title-label">Document title</p>
    <h3 class="title">{{document.title}}</h3>
  </section>
  <div mat-dialog-content>
    <div class="spinner-container" *ngIf="loading$ | async">
      <mat-spinner></mat-spinner>
    </div>
    <section *ngFor="let attachment of document.attachments" class="dialog-content"  [hidden]="loading$ | async">
      <section class="attachment-row" fxLayout="row" fxLayoutAlign="space-between center">
        <section fxLayout="row" fxLayoutAlign="start center">
          <img [alt]="attachment.contentType" [src]="attachmentIcon(attachment)" class="mime-type-icon">
          <p  class="attachment-name">{{attachment.name}}</p>
        </section>

        <button mat-button (click)="getDownloadLink(attachment)">
          <mat-icon class="grey">cloud_download</mat-icon>
        </button>
      </section>
    </section>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <section class="footer">
      <button mat-button mat-dialog-close [mat-dialog-close]="false" class="blue" (click)="close()">CLOSE</button>
      <button mat-button class="confirm-action-btn blue" (click)="downloadAll()"> DOWNLOAD ALL</button>
    </section>
  </div>
</section>

