<section>
  <button class="help" mat-icon-button matTooltip="Help" [matMenuTriggerFor]="appMenu">
    <mat-icon>help</mat-icon>
  </button>
  <mat-menu #appMenu>
    <a mat-menu-item target="_blank" href="https://support.aodocs.com/hc/en-us/articles/360015686831-Install-the-AODocs-Retention-App-and-Module">{{'help.knowledgebase' | translate}}</a>
    <mat-divider></mat-divider>
    <a mat-menu-item target="_blank" href="https://aodocs.zendesk.com/">{{'help.support' | translate}}</a>
    <mat-divider></mat-divider>
    <a mat-menu-item target="_blank" href="https://status.aodocs.com/">{{'help.statuspage' | translate}}</a>
    <mat-divider></mat-divider>
    <a mat-menu-item target="_blank" href="https://www.aodocs.com/privacy-policy/">{{'help.privacy' | translate}}</a>
    <mat-divider></mat-divider>
    <a mat-menu-item target="_blank" (click)="openAboutDialog()">{{'help.about' | translate}}</a>
  </mat-menu>
</section>
