import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ActiveLibrariesComponent} from './active/active-libraries.component';
import {PendingLibrariesComponent} from './pending/pending-libraries.component';
import {LibrariesDetailComponent} from './detail/libraries-detail.component';

const routes: Routes = [
  {
    path: 'active',
    component: ActiveLibrariesComponent
  },
  {
    path: 'pending',
    component: PendingLibrariesComponent
  },
  {
    path: 'active/library/:id',
    component: LibrariesDetailComponent
  },
  {
    path: 'pending/library/:id',
    component: LibrariesDetailComponent
  },
  {
    path: '', redirectTo: '/libraries/active', pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LibrariesRoutingModule {
}
