<mat-nav-list role="navigation" fxHide.xs>
  <span *ngFor="let item of menu; let first = first;">
    <span *ngIf="item.children && item.children.length > 0" [hidden]="item.hide">
      <mat-expansion-panel class="expansion-menu" [expanded]="first" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p class="menu-item"> {{'menu.' + item.name | translate}}</p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <span *ngFor="let child of item.children" [hidden]="child.hide">
          <a mat-list-item class="menu-item"
            routerLink="{{child.route}}"
            [routerLinkActive]="['is-active', 'is-active-sub']">
            <p class="menu-item"> {{'menu.' + child.name | translate}}</p>
          </a>
        </span>
      </mat-expansion-panel>
    </span>
    <span *ngIf="!item.children || item.children.length === 0"  [hidden]="item.hide">
      <a mat-list-item class="menu-item"  routerLink="{{item.route}}" [routerLinkActive]="['is-active']">
        <p class="menu-item">{{'menu.' + item.name | translate}}</p>
      </a>
    </span>
  </span>
</mat-nav-list>

<button mat-icon-button [matMenuTriggerFor]="dropMenu" fxHide fxShow.xs>
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #dropMenu="matMenu">
  <ng-container *ngFor="let item of menu">
    <span *ngIf="!item.children || item.children.length === 0"  [hidden]="item.hide">
      <button mat-menu-item routerLink="{{item.route}}">
        {{'menu.' + item.name | translate}}
      </button>
    </span>
    <span *ngIf="item.children && item.children.length > 0"  [hidden]="item.hide">
      <button mat-menu-item  [matMenuTriggerFor]="subMenu">
        {{'menu.' + item.name | translate}}
      </button>
      <mat-menu #subMenu="matMenu">
        <span *ngFor="let child of item.children">
          <button routerLink="{{child.route}}" [hidden]="child.hide"
            mat-menu-item>{{'menu.' + child.name | translate}}</button>
        </span>
      </mat-menu>
    </span>
    <mat-divider></mat-divider>
  </ng-container>
</mat-menu>
