import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {ApiVersion} from '../constants/apis';
import {Folder} from '../models/folder.model';
import {BindRetentionsCommand, UnbindRetentionsCommand} from '../models/retention.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FolderService extends AbstractService {

  constructor(private http: HttpClient) {
    super();
  }

  path(folderId: string): Observable<{ items: Folder[] }> {
    const url = this.toUrl('library', 'folder/' + folderId + '/path', ApiVersion.V1);
    return this.http.get<{ items: Folder[] }>(url);
  }

  children(folderId: string): Observable<{ items: Folder[] }> {
    const url = this.toUrl('library', 'folder/' + folderId, ApiVersion.V1);
    return this.http.get<{ items: Folder[] }>(url);
  }

  bind(libraryId: string, folderId: string, policyIds: BindRetentionsCommand): Observable<void> {
    const url = this.toUrl('library', 'library/' + libraryId + '/folder/' + folderId + '/bindretentions', ApiVersion.V1);
    return this.http.post<void>(url, policyIds);
  }

  unbind(libraryId: string, folderId: string, policyIds: UnbindRetentionsCommand): Observable<void> {
    const url = this.toUrl('library', 'library/' + libraryId + '/folder/' + folderId + '/unbind', ApiVersion.V1);
    return this.http.post<void>(url, policyIds);
  }
}
