
import { NgModule } from '@angular/core';
import { CommonModule} from '@angular/common';
import {HoldsRoutingModule} from './holds-routing.module';
import {SharedModule} from '../shared/shared.module';
import {LockManagementComponent} from './lock-management/lock-management.component';
import {MatCardModule} from '@angular/material/card';
import {TranslateModule} from '@ngx-translate/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {FlexModule} from '@angular/flex-layout';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {LegalHoldListComponent} from './legal-hold/list/legal-hold-list.component';
import {LegalHoldCreatorComponent} from './legal-hold/creator/legal-hold-creator.component';
import {MatDialogModule} from '@angular/material/dialog';
import { LegalHoldReleaserComponent } from './legal-hold/releaser/legal-hold-releaser.component';



@NgModule({
  declarations: [LockManagementComponent, LegalHoldListComponent, LegalHoldCreatorComponent, LegalHoldReleaserComponent],
  imports: [
    CommonModule,
    HoldsRoutingModule,
    SharedModule,
    MatCardModule,
    TranslateModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    FlexModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    TranslateModule.forChild({
      extend: true
    })
  ]
})
export class HoldsModule { }
