import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {SuccessSnackbarComponent} from '../modules/shared/success-snackbar/success-snackbar.component';


@Injectable({
  providedIn: 'root'
})
export class CustomSnackBarService {

  private readonly SNACKBAR_TIME: number = 2.5;
  private horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  private verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(private snackBar: MatSnackBar) {
  }

  public openSuccessSnackbar(message: string): void{
    this.snackBar.openFromComponent(SuccessSnackbarComponent, {
      duration: this.SNACKBAR_TIME * 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      data: {message}
    });
  }
}
