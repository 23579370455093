import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LibrariesModule} from './modules/libraries/libraries.module';
import {PoliciesModule} from './modules/policies/policies.module';
import {ReportsModule} from './modules/reports/reports.module';
import {MenuComponent} from './components/menu/menu.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {HeaderComponent} from './components/header/header.component';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {ProfileComponent} from './components/header/profile/profile.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import {HelpComponent} from './components/header/help/help.component';
import {MatDialogModule} from '@angular/material/dialog';
import {AboutDialogComponent} from './components/header/about-dialog/about-dialog.component';
import {HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {DomainViewerComponent} from './components/header/domain-viewer/domain-viewer.component';
import {BlankPageComponent} from './components/blank-page/blank-page.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {SharedModule} from './modules/shared/shared.module';
import {DialogComponent} from './components/dialog/dialog.component';
import {AlertQuarterComponent} from './components/alert-quarter/alert-quarter.component';
import {CsvImportStatisticsComponent} from './components/csv-import-statistics/csv-import-statistics.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatStepperModule} from '@angular/material/stepper';
import {EditFolderPolicyComponent} from './components/edit-folder-policy/edit-folder-policy.component';
import {EditFolderPolicyDialogComponent} from './components/edit-folder-policy-dialog/edit-folder-policy-dialog.component';
import {SettingsModule} from './modules/settings/settings.module';
import {HoldsModule} from './modules/holds/holds.module';
import {RetainedDocumentAttachmentsDialogComponent} from './components/retained-document-attachments-dialog/retained-document-attachments-dialog.component';
import {UploadErrorsModule} from './modules/upload-errors/upload-errors.module';
import {AuditModule} from './modules/audit/audit.module';
import {LegalHoldRuleFieldComponent} from './components/legal-hold-rule-field/legal-hold-rule-field.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import '@aodocs/authentication';
import {AuthTokenInterceptor} from './interceptors/request.interceptor';


@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HeaderComponent,
    ProfileComponent,
    HelpComponent,
    AboutDialogComponent,
    DomainViewerComponent,
    BlankPageComponent,
    DialogComponent,
    AlertQuarterComponent,
    CsvImportStatisticsComponent,
    EditFolderPolicyComponent,
    EditFolderPolicyDialogComponent,
    RetainedDocumentAttachmentsDialogComponent,
    LegalHoldRuleFieldComponent],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    LibrariesModule,
    PoliciesModule,
    ReportsModule,
    HoldsModule,
    SettingsModule,
    UploadErrorsModule,
    AuditModule,
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatExpansionModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    MatListModule,
    MatDialogModule,
    HttpClientModule,
    MatFormFieldModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDatepickerModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true
    }
  ],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function translateHttpLoaderFactory(httpBackend: HttpBackend): TranslateHttpLoader {
  return new TranslateHttpLoader(new HttpClient(httpBackend));
}

