import {Component, Inject, OnInit} from '@angular/core';
import {Folder} from '../../models/folder.model';
import {LibraryDetails} from '../../models/library.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-edit-folder-policy-dialog',
  templateUrl: './edit-folder-policy-dialog.component.html',
  styleUrls: ['./edit-folder-policy-dialog.component.scss']
})
export class EditFolderPolicyDialogComponent implements OnInit {
  public folder: string;
  public isRoot: boolean;
  public libraryDetails: LibraryDetails;
  public saveSubject = new Subject<boolean>();

  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialogRef: MatDialogRef<EditFolderPolicyDialogComponent>) {
    this.folder = data.folder;
    this.isRoot = data.isRoot;
    this.libraryDetails = data.libraryDetails;
  }

  ngOnInit(): void {
    this.saveSubject.subscribe((value) => {
      if (!value) {
        this.dialogRef.close(true);
      }
    });
  }

  save(): void {
    this.saveSubject.next(true);
  }

}
