import {AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';


export function BothOrNoneValidator(): ValidatorFn {
  return (group: FormGroup): ValidationErrors => {
    const control1 = group.controls.property;
    const control2 = group.controls.value;
    if (control1.value && control2.value || !control1.value && !control2.value) {
      control2.setErrors(null);
    } else {
      control2.setErrors({bothOrNone: true});
    }
    return;
  };
}

export function duplicateValidator(): ValidatorFn {
  return (formArray: FormArray) => {
    const totalSelected = formArray.controls.map(control => control.value);
    const names = totalSelected.map(value => value.property);
    const hasDuplicate = names.some(
      (name, index) => names.indexOf(name, index + 1) !== -1
    );
    return hasDuplicate ? {duplicate: true} : null;
  };
}

export function endDateStartDateValidator(startDate: string, endDate: string, errorName: string = 'fromToDate'): ValidatorFn {
  return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
    const fromDate = formGroup.get(startDate).value;
    const toDate = formGroup.get(endDate).value;
    if ((fromDate !== null && toDate !== null) && fromDate > toDate) {
      return {[errorName]: true};
    }
    return null;
  };
}

export function forbiddenNameValidator(nameRe: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const forbidden = nameRe.test(control.value);
    return forbidden ? null : {forbiddenName: control.value};
  };
}
