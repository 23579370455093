<div class="spinner-container" *ngIf="loading$ | async">
  <mat-spinner></mat-spinner>
</div>
<section [hidden]="loading$ | async">
  <mat-table [dataSource]="dataSource" id="assigned-table">
    <!-- folder name -->
    <ng-container matColumnDef="folderName">
      <mat-header-cell *matHeaderCellDef>{{'libraries.edit.folders.table.folder'|translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-icon *ngIf="isRoot(element.folderId)" class="root-icon grey">home</mat-icon>
        {{element.folderName}}
      </mat-cell>
    </ng-container>

    <!-- retention policy -->
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef>{{'libraries.edit.folders.table.retention'|translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.retentionId}} </mat-cell>
    </ng-container>

    <!-- created -->
    <ng-container matColumnDef="created">
      <mat-header-cell *matHeaderCellDef>{{'libraries.edit.folders.table.created'|translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.createdAtTimestamp | date:'medium'}} </mat-cell>
    </ng-container>

    <!-- modified -->
    <ng-container matColumnDef="modified">
      <mat-header-cell *matHeaderCellDef> {{'libraries.edit.folders.table.modified'|translate}}</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.lastModifiedAtTimestamp | date:'medium'}} </mat-cell>
    </ng-container>

    <!-- Unbind -->
    <ng-container matColumnDef="unbind">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-button mat-icon-button (click)="confirmUnbind(element)"
                matTooltip="{{'libraries.edit.folders.table.tooltips.unbind' | translate}}">
          <mat-icon class="unbind-icon grey" svgIcon="unbind"></mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <!-- action -->
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button *ngIf="isRoot(element.folderId)" mat-button mat-icon-button (click)="edit(element)" [disabled]="isError"
                class="table-button grey"
                matTooltip="{{'libraries.edit.folders.table.tooltips.edit' | translate}}">
          <mat-icon>edit</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnsToDisplay;"></mat-row>
  </mat-table>
</section>
