<section class="main-content">
  <h1>{{'title.deleted' | translate}}</h1>

  <mat-card class="unlocked-document-card no-padding" >

    <p class="grey-label padding-section">
      {{'report.deletedDocuments.subtitle' | translate}}
    </p>

    <div class="spinner-container" *ngIf="loading$ | async">
      <mat-spinner></mat-spinner>
    </div>

    <section  [hidden]="loading$ | async">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" >

        <!-- deletionBatchId -->
        <ng-container matColumnDef="deletionBatchId">
          <mat-header-cell *matHeaderCellDef>{{'report.deletedDocuments.deletionBatchId' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
        </ng-container>

        <!-- executionDate -->
        <ng-container matColumnDef="executionDate">
          <mat-header-cell *matHeaderCellDef>{{'report.deletedDocuments.executionDate' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.createdAtTimestamp | date:'medium'}} </mat-cell>
        </ng-container>

        <!-- view -->
        <ng-container matColumnDef="view">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-button mat-icon-button (click)="viewDetails(element.id)"
                    matTooltip="{{'report.deletedDocuments.detailsTooltip' | translate}}" class="table-button">
              <mat-icon svgIcon="view_data"></mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <!-- export -->
        <ng-container matColumnDef="export">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">

            <button mat-button class="btn-background-blue fw-500" (click)="exportDeletedDocsForDomainJob(element.id)">
              {{'report.deletedDocuments.exportButtonLabel' | translate}}
            </button>

          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns()"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns();"></mat-row>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">{{"dataNotFound" | translate}}</td>
        </tr>
      </table>
    </section>
    <section [hidden]="loading$ | async">
      <mat-paginator [pageSizeOptions]="[10,20,30]"></mat-paginator>
    </section>

  </mat-card>
</section>
