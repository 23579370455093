import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {AuditLog, AuditLogFilterValues} from './audit-log.model';
import {NgZone} from '@angular/core';
import {DialogService} from '../services/dialog.service';
import {AuditLogService} from '../services/audit-log.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {DEFAULT_LIBRARIES_ITEMS_PAGE_NUMBER} from '../constants/library-constants';

export class AuditLogDataSource implements DataSource<AuditLog> {

  private auditLogsSubject = new BehaviorSubject<AuditLog[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public auditLogs: AuditLog[] = [];

  constructor(private auditLogService: AuditLogService, private ngZone: NgZone, private dialogService: DialogService) {
  }

  connect(collectionViewer: CollectionViewer): Observable<AuditLog[]> {
    return this.auditLogsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.auditLogsSubject.complete();
    this.loadingSubject.complete();
  }

  loadAuditLogs(limit: number, offset: number, filters: AuditLogFilterValues): void {
    this.loadingSubject.next(true);
    this.auditLogService.list(limit, offset, filters).subscribe({
      next: (resp) => {
        this.loadingSubject.next(false);
        if (!resp.items) {
          resp.items = [];
        }
        this.auditLogsSubject.next(resp.items);
        this.loadingSubject.next(false);
        this.auditLogs.push(...resp.items);
      }, error: (error) => {
        this.loadingSubject.next(false);
        this.dialogService.error(error?.error?.error?.message ?? error.message);

      }
    });
  }

  public loadAuditLogPage(eventPageIndex: number,
                          filters: AuditLogFilterValues,
                          pageIndex = 0,
                          pageSize = DEFAULT_LIBRARIES_ITEMS_PAGE_NUMBER): void {

    const start = eventPageIndex * pageSize;
    const end = (eventPageIndex + 1) * pageSize;

    if (start >= this.auditLogs.length) {
      this.loadAuditLogs(pageSize, eventPageIndex * pageSize, filters);
    } else {
      this.auditLogsSubject.next(this.auditLogs.slice(start, end));
    }
  }

  init(limit: number, offset: number, filters: AuditLogFilterValues): void {
    this.auditLogs = [];
    this.loadAuditLogs(limit, offset, filters);
  }
}
