import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-domain-viewer',
  templateUrl: './domain-viewer.component.html',
  styleUrls: ['./domain-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DomainViewerComponent implements OnInit, OnDestroy {
  public domain: string;
  public loading = true;

  constructor(private userService: UserService) {
  }

  ngOnInit(): void {
    this.userService.userSubject.subscribe((user) => {
      user ? this.domain = user.domain : this.domain = null;
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    this.userService.userSubject.unsubscribe();
  }
}
