import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {ApiVersion} from '../constants/apis';
import {Policy} from '../models/retention.model';
import {DateTime, Interval} from 'luxon';
import {RetentionImportStatistics} from '../models/retention-api-response';
import {codesExportFileHeaders, retentionCodeKeys} from '../constants/retention-constants';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RetentionService extends AbstractService {

  constructor(private http: HttpClient) {
    super();
  }

  create(policy: Policy): Observable<Policy> {
    const url = this.toUrl('retention', 'save', ApiVersion.V1);
    return this.http.post<Policy>(url, policy);
  }

  list(): Observable<{ items: Policy[] }> {
    const url = this.toUrl('retention', 'list/all', ApiVersion.V1);
    return this.http.get<{ items: Policy[] }>(url);
  }

  active(): Observable<{ items: Policy[] }> {
    const url = this.toUrl('retention', 'list/active', ApiVersion.V1);
    return this.http.get<{ items: Policy[] }>(url);
  }

  activeFinraFiltered(filterDomainFeaturesParams: Map<string, string>): Observable<{ items: Policy[] }> {
    const urlFiltered = this.toUrl('retention', 'list/active/filtered', ApiVersion.V1);
    let params =  new HttpParams();

    for (let [key, value] of filterDomainFeaturesParams) {
      params = params.set(key, value);
    }

    return this.http.get<{ items: Policy[] }>(urlFiltered, {params});
  }

  delete(policyId: string): Observable<void> {
    const url = this.toUrl('retention', 'delete/' + policyId, ApiVersion.V1);
    return this.http.delete<void>(url);
  }

  importCsv(content: string | ArrayBuffer): Observable<RetentionImportStatistics> {
    const url = this.toUrl('retention', 'importCsv', ApiVersion.V1);
    return this.http.post<RetentionImportStatistics>(url, content);
  }

  convertArrayOfObjectsToCSV(args: {}): string {
    let result: string;
    let ctr: number;
    let columnDelimiter: string;
    let lineDelimiter: string;
    let policyCodes: string[];
    let header: string[];

    policyCodes = Object['values'](args['data']) || null;

    columnDelimiter = ',';
    lineDelimiter = '\n';
    header = retentionCodeKeys;
    result = codesExportFileHeaders.join(columnDelimiter) + lineDelimiter;

    if (policyCodes === null || !policyCodes.length) {
      return result;
    }

    policyCodes.forEach((item) => {
      ctr = 0;
      header.forEach((key) => {
        if (ctr > 0) {
          result += columnDelimiter;
        }
        result += item[key] === undefined || item[key] === null ? '' : '"' + item[key] + '"';
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  isInQuarterInterval(date: DateTime): boolean {
    const startFirstInterval = DateTime.local(date.year, 3, 30, 0, 0, 0);
    const endFirstInterval = DateTime.local(date.year, 4, 10, 23, 59, 59);

    const startSecondInterval = DateTime.local(date.year, 6, 30, 23, 59, 59);
    const endSecondInterval = DateTime.local(date.year, 7, 10, 0, 0, 0);

    const startThirdInterval = DateTime.local(date.year, 9, 30, 0, 0, 0);
    const endThirdInterval = DateTime.local(date.year, 10, 10, 23, 59, 59);

    const startFourthInterval = DateTime.local(date.year, 12, 30, 0, 0, 0);
    const endFourthInterval = DateTime.local(date.year + 1, 1, 10, 23, 59, 59);

    const firstInterval = Interval.fromDateTimes(startFirstInterval, endFirstInterval);
    const secondInterval = Interval.fromDateTimes(startSecondInterval, endSecondInterval);
    const thirdInterval = Interval.fromDateTimes(startThirdInterval, endThirdInterval);
    const fourthInterval = Interval.fromDateTimes(startFourthInterval, endFourthInterval);

    return firstInterval.contains(date) || secondInterval.contains(date) || thirdInterval.contains(date) || fourthInterval.contains(date);
  }
}
