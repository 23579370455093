import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {RetentionService} from '../../../services/retention.service';
import {Policy} from '../../../models/retention.model';
import {DialogService} from '../../../services/dialog.service';
import {ReportingService} from '../../../services/reporting.service';
import {CustomSnackBarService} from '../../../services/custom-snackbar.service';
import {TranslateService} from '@ngx-translate/core';
import {RETENTION_BINDINGS_ALL_BINDINGS_SELECTOR} from '../../../constants/reporting-constants';


@Component({
  selector: 'app-reports-assignment',
  templateUrl: './reports-assignment.component.html',
  styleUrls: ['./reports-assignment.component.scss']
})
export class ReportsAssignmentComponent implements OnInit {

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public policies: Policy[] = [];
  public loading$ = this.loadingSubject.asObservable();

  public retentionReportForm: FormGroup;
  public retentionSelectorControl = new FormControl();
  public exportButtonDisabled = false;

  public filteredOptions: Observable<Policy[]>;

  constructor(private retentionService: RetentionService,
              private reportingService: ReportingService,
              private dialogService: DialogService,
              private formBuilder: FormBuilder,
              private customSnackBarService: CustomSnackBarService,
              private translate: TranslateService) {

    this.retentionReportForm = formBuilder.group({
      retention: this.retentionSelectorControl,
    });
  }

  ngOnInit(): void {
    this.loadPoliciesAndInitOptions();
  }

  initFilteredOpt(): void {
    this.filteredOptions = this.retentionSelectorControl.valueChanges
      .pipe(
        startWith(''),

        map(value => {
          return typeof value === 'string' ? value : value.id;
        }),

        map(name => {
          if (!name) {
            this.exportButtonDisabled = false;
          }
          return name ? this._filter(name) : this.policies.slice();
        })
      );
  }

  displayFn(policy: Policy): string {
    return policy && policy.id ? policy.id : '';
  }

  private _filter(name: string): Policy[] {
    const filterValue = name.toLowerCase();
    const filteredPolicies = this.policies.filter(policy => policy.id.toLowerCase().indexOf(filterValue) === 0);

    if (filteredPolicies.length === 0 && filterValue) {
      this.exportButtonDisabled = true;
    }

    if (filteredPolicies.length > 0) {
      this.exportButtonDisabled = !this.matchInputWithFilteredPolicies(filterValue, filteredPolicies);
    }
    return filteredPolicies;
  }

  private matchInputWithFilteredPolicies(filterValue: string, policies: Policy[]): boolean {
    const filteredPolicies = policies.filter(policy => policy.id.toLowerCase() === filterValue);
    return filteredPolicies.length > 0;
  }

  loadPoliciesAndInitOptions(): void {
    this.loadingSubject.next(true);
    this.retentionService.list().subscribe({
      next: (resp) => {
        this.policies = resp.items;
        this.initFilteredOpt();
        this.loadingSubject.next(false);
      },
      error: (error) => {
        this.loadingSubject.next(false);
        this.dialogService.error(error?.error?.error?.message ?? error.message);
      }
    });
  }

  launchReport(): void {
    const retentionCode = this.retentionReportForm.getRawValue();
    const selectedRetention = (retentionCode.retention === null || retentionCode.retention === '') ?
      RETENTION_BINDINGS_ALL_BINDINGS_SELECTOR : retentionCode.retention.id;
    this.reportingService.exportRetentionBindings(selectedRetention).subscribe({
      next: () => {
        this.customSnackBarService.openSuccessSnackbar(this.translate.instant('snackBarMessage.exportStarted'));
        this.retentionReportForm.reset();
        this.exportButtonDisabled = true;
        this.initFilteredOpt();
      },
      error: error => this.dialogService.error(error?.error?.error?.message ?? error.message)
    });
  }

  optionSelected(): void {
    this.exportButtonDisabled = false;
  }

}
