import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../models/dialog.model';
import {DialogComponent} from '../components/dialog/dialog.component';
import {DialogType} from '../constants/dialog-constants';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog, private translate: TranslateService) {
  }

  plain(msg: string, title: string): MatDialogRef<DialogComponent, boolean> {
    const dialogData: DialogData = {
      message: msg, title,
      type: DialogType.PLAIN
    };
    return this.open(dialogData);
  }

  info(msg: string, title?: string): MatDialogRef<DialogComponent, boolean> {
    const dialogTitle = this.translate.instant('dialog.info');
    const dialogData: DialogData = {
      message: msg,
      title: title || dialogTitle,
      type: DialogType.INFO
    };
    return this.open(dialogData);
  }

  warning(msg: string, title?: string): MatDialogRef<DialogComponent, boolean> {
    const dialogTitle = this.translate.instant('dialog.warning');
    const dialogData: DialogData = {
      message: msg,
      title: title || dialogTitle,
      type: DialogType.WARNING
    };
    return this.open(dialogData);
  }

  error(msg: string, title?: string): MatDialogRef<DialogComponent, boolean> {
    const [exception, ...message] = msg.split(':');
    const errorMsg = message.length > 0 ? message.join(' ') : exception;
    const dialogTitle = this.translate.instant('dialog.error');
    const dialogData: DialogData = {
      message: errorMsg,
      title: title || dialogTitle,
      type: DialogType.ERROR
    };
    return this.open(dialogData);
  }

  open(dialogData: DialogData): MatDialogRef<DialogComponent, boolean> {
    return this.dialog.open(DialogComponent, {data: dialogData});
  }
}
