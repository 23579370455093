<div class="spinner-container" *ngIf="loading$ | async">
  <mat-spinner></mat-spinner>
</div>
<section class="top-margin-section" [hidden]="loading$ | async">
  <mat-label class="grey-label">{{'libraries.edit.assigner.step2.folder'|translate}}</mat-label>
  <h3 class="path-style" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon class="grey">home</mat-icon>
    {{libraryDetails.displayName}}</h3>
</section>
<section class="top-margin-section" [hidden]="loading$ | async">
  <mat-label class="grey-label margin-bottom-label">{{'libraries.edit.assigner.step2.choose'|translate}}</mat-label>
  <app-chips [allItems]="preSelectedPolicies"
             [selectedItems]="policiesInSelectedFolder"
             [selectedEventSubject]="selectedPolicyFromChipSubject"
             [clearSubject]="clearPolicyChipsSubject"
             [label]="'Retention ID'"></app-chips>
</section>
