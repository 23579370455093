import {Component, OnInit} from '@angular/core';
import {NavItem} from '../../models/nav-item';
import {UserService} from '../../services/user.service';
import {UserClassification} from '../../constants/user-constants';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  menu: NavItem[] = [];
  isFinra: boolean;

  constructor(private userService: UserService) {
  }

  ngOnInit(): void {
    this.isFinra = this.userService.isFinra();
    this.initMenu();
  }

  initMenu(): void {
    this.menu = [
      {
        name: 'libraries',
        hide: this.hideFor([UserClassification.AUDITOR]),
        children: [
          {
            name: 'active',
            route: '/libraries/active'
          },
          {
            name: 'pending',
            route: '/libraries/pending'
          }
        ]
      },
      {
        name: 'policy',
        route: '/policies',
        hide: this.hideFor([UserClassification.AUDITOR]),
      },
      {
        name: 'reports',
        hide: this.hideFor([UserClassification.AUDITOR]),
        children: [
          {
            name: 'assignment',
            route: '/reports/assignment'
          },
          {
            name: 'deleted',
            route: '/reports/deleted-documents'
          },
          {
            name: 'tobedeleted',
            route: '/reports/to-be-deleted-documents'
          }
        ]
      },
      {
        name: 'lockunlock',
        hide: this.isFinra || this.hideFor([UserClassification.AUDITOR]),
        route: '/holds/lock-management'
      },
      {
        name: 'holds',
        hide: !this.isFinra,
        children: [
          {
            name: 'lockunlock',
            hide: this.hideFor([UserClassification.AUDITOR]),
            route: '/holds/lock-management'
          },
          {
            name: 'legalHolds',
            route: '/holds/legal-holds'
          }
        ]
      },
      {
        name: 'audit',
        hide: this.hideFor([UserClassification.USER]) || !this.isFinra,
        children: [
          {
            name: 'auditLogs',
            route: '/audit/logs'
          },
          {
            name: 'retaineddocuments',
            route: '/audit/retained-documents'
          }
        ]
      },
      {
        name: 'uploadErrors',
        route: '/upload',
        hide: this.hideFor([UserClassification.AUDITOR, UserClassification.USER]) || !this.isFinra,
      },
      {
        name: 'settings',
        route: '/settings',
        hide: this.hideFor([UserClassification.AUDITOR]) || !this.isFinra,
      }
    ];

  }

  hideFor(userClassifications: UserClassification[]): boolean {
    let value = false;
    for (const userClassification of userClassifications) {
      if (userClassification === UserClassification.AUDITOR && this.userService.isAuditor()) {
        value = true;
        break;
      } else if (userClassification === UserClassification.USER && this.userService.isSimpleUser()) {
        value = true;
        break;
      }
    }
    return value;
  }

}
