<section class="main-content">
  <h1>{{'title.legalHold' | translate}}</h1>
  <mat-card class="no-padding">
    <section fxLayout="row" fxLayoutAlign="space-between center" class="sub-title-container">
      <p class="black-title-label">{{'title.legalHoldSub' | translate}}</p>
      <section fxLayout="row">
        <button mat-icon-button (click)="create()" class="action-icon"
                matTooltip="{{'legalHold.list.add' | translate}}"
                *ngIf="dataSource.data.length > 0 && !isAuditor()">
          <mat-icon>add</mat-icon>
        </button>
        <button mat-icon-button class="action-icon" (click)="refresh()"
                matTooltip="{{'libraries.table.tooltips.refresh' | translate}}">
          <mat-icon>refresh</mat-icon>
        </button>
      </section>
    </section>
    <div class="spinner-container" *ngIf="loading$ | async">
      <mat-spinner></mat-spinner>
    </div>
    <section [hidden]="loading$ | async">
      <section fxLayout="column" fxLayoutAlign="start" class="no-holds-container" *ngIf="dataSource.data.length === 0 && !isAuditor()">
        <p class="grey-label">{{'legalHold.list.noHolds' | translate}}</p>
        <button mat-button (click)="create()"
                class="new-hold-btn">  {{'legalHold.list.newHoldButton'|translate}}</button>
      </section>
      <section fxLayout="row" fxLayoutAlign="start">

        <table mat-table [dataSource]="dataSource" id="legal-hold-table" class="mat-elevation-z8"
               [hidden]="dataSource.data.length === 0">

          <ng-container matColumnDef="label">
            <mat-header-cell *matHeaderCellDef><span>{{'legalHold.list.table.label' | translate}}</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element"><span>{{element.id}}</span>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="created">
            <mat-header-cell *matHeaderCellDef><span>{{'legalHold.list.table.created'  | translate}}</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element"><span> {{element.created | date: 'dd/MM/yyyy'}}  </span>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="rules">
            <mat-header-cell *matHeaderCellDef>{{'legalHold.list.table.rules' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element"><span>  {{convertRules(element.rules)}}</span></mat-cell>
          </ng-container>


          <ng-container matColumnDef="documents">
            <mat-header-cell *matHeaderCellDef><span>{{'legalHold.list.table.documents'| translate}}</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span [hidden]="!element.applied">{{documentCount(element)}}</span>
              <div id="spinner-document-container" class="spinner-container" *ngIf="!element.applied">
                <mat-spinner [diameter]="20"></mat-spinner>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="released">
            <mat-header-cell *matHeaderCellDef><span>{{'legalHold.list.table.released'| translate}}</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-icon *ngIf="element.released"
                        [matTooltip]="element.releaseDate | date: 'dd/MM/yyyy'"
                        class="green">check</mat-icon>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="edit">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-button mat-icon-button (click)="view(element)"
                      [disabled]="element.released"
                      *ngIf="!isAuditor()"
                      class="table-button">
                <mat-icon>edit</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns()"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns();"></mat-row>
        </table>
      </section>
      <section [hidden]="dataSource.data.length === 0">
        <mat-paginator [pageSizeOptions]="[10,20,30]"></mat-paginator>
      </section>
    </section>
  </mat-card>
</section>
