import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Route,
  RouterStateSnapshot
} from '@angular/router';
import {UserService} from '../user.service';
import {UserClassification} from '../../constants/user-constants';
import {RoutePermissions} from './route-permissions';

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService implements CanActivate, CanActivateChild {

  constructor(private userService: UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.userService.user) {
      return false;
    }
    const path = this.manageModuleChildrenGranularAccess(route.routeConfig, state.url);
    return this.allow(path);
  }

  allow(path: string): boolean {
    const userClassification = this.userService.user.userClassification;
    const allowedClassifications: UserClassification[] = RoutePermissions[path];
    return allowedClassifications.indexOf(userClassification) !== -1;
  }

  manageModuleChildrenGranularAccess(routeConfig: Route, url: string): string {
    let path = routeConfig.path;
    // Manage legal holds case
    if (url.includes('legal-holds')) {
      path = 'legal';
    } else if (url.includes('lock-management')) {
      path = 'holds';
    }
    return path;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }
}
