import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AboutDialogComponent implements OnInit {

  version = environment.version;

  constructor() {
  }

  ngOnInit(): void {
  }

}
