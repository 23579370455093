<section id="edit-schedule-dialog">
  <h2 mat-dialog-title class="header">{{'libraries.edit.assigner.edit'|translate}}</h2>
  <div mat-dialog-content>
    <app-edit-folder-policy [libraryDetails]="libraryDetails" [folder]="folder" [isRoot]="isRoot"
                              [saveSubject]="saveSubject"></app-edit-folder-policy>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button mat-dialog-close [mat-dialog-close]="false" class="grey"> {{'policies.editor.cancel'|translate}}</button>
    <button mat-button class="confirm-action-btn" (click)="save()"> {{'policies.editor.save'|translate}}</button>
  </div>
</section>
