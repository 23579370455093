import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  NgZone,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogService} from '../../../../services/dialog.service';
import {LegalHoldRulesTypeList} from '../../../../constants/legal-hold-constants';
import {LegalHoldService} from '../../../../services/legal-hold.service';
import {LegalHold} from '../../../../models/legal-hold';
import {LegalHoldRuleFieldComponent} from '../../../../components/legal-hold-rule-field/legal-hold-rule-field.component';


@Component({
  selector: 'app-legal-hold-creator',
  templateUrl: './legal-hold-creator.component.html',
  styleUrls: ['./legal-hold-creator.component.scss']
})
export class LegalHoldCreatorComponent implements OnInit {

  public loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public creationForm: FormGroup;
  @ViewChild('viewContainerRef', {read: ViewContainerRef})
  public VCR: ViewContainerRef;
  public childUniqueKey = 0;
  public componentsReferences = Array<ComponentRef<LegalHoldRuleFieldComponent>>();
  public legalHoldPropertyTypes = LegalHoldRulesTypeList;
  public legalHoldRulesTypeToSelect = [];

  constructor(private fb: FormBuilder,
              private cdr: ChangeDetectorRef,
              private holdService: LegalHoldService,
              private ngZone: NgZone,
              private dialog: MatDialog,
              private dialogService: DialogService,
              private dialogRef: MatDialogRef<LegalHoldCreatorComponent>,
              private CFR: ComponentFactoryResolver) {
  }

  ngOnInit(): void {
    this.initCreationForm();
  }

  initCreationForm(): void {
    this.creationForm = new FormGroup({
      type: new FormControl(),
      number: new FormControl()
    });
  }

  create(): void {
    const rawForm = this.creationForm.getRawValue();
    const legalHold: LegalHold = {
      id: null,
      type: rawForm.type,
      controlNumber: rawForm.number,
      created: Date.now(),
      rules: this.retrieveRules().reduce((acc, {property, value}) => ({...acc, [property]: value}), {}),
      released: false,
      applied: false,
      documents: []
    };
    console.log(legalHold);
    this.loadingSubject.next(true);
    this.holdService.create(legalHold).subscribe({
      next: () => {
        this.loadingSubject.next(false);
        this.dialogRef.close(true);
      },
      error: error => {
        this.loadingSubject.next(false);
        this.ngZone.run(() => this.dialogService.error(error.result.error.message));
        this.dialogRef.close(false);
      }
    });
  }

  addRule(): void {
    const componentFactory = this.CFR.resolveComponentFactory(LegalHoldRuleFieldComponent);
    const ruleComponentRef = this.VCR.createComponent(componentFactory);

    const childComponent = ruleComponentRef.instance;
    this.legalHoldRulesTypeToSelect = [...this.legalHoldPropertyTypes];
    this.componentsReferences.forEach(el => {
      this.RemoveRuleType(el.instance.form.getRawValue().property);
    });
    childComponent.legalHoldRulesTypeToSelect = this.legalHoldRulesTypeToSelect;
    childComponent.uniqueKey = ++this.childUniqueKey;
    childComponent.parentRef = this;

    this.componentsReferences.push(ruleComponentRef);

  }

  remove(key: number): void {
    if (this.VCR.length < 1) {
      return;
    }
    const componentRef = this.componentsReferences.filter(
      x => x.instance.uniqueKey === key
    )[0];
    const idx = this.componentsReferences.indexOf(componentRef);

    // removing component from container
    this.VCR.remove(idx);

    // removing component from the list
    this.componentsReferences = this.componentsReferences.filter(
      x => x.instance.uniqueKey !== key
    );
  }


  RemoveRuleType(key: string): void {
    this.legalHoldRulesTypeToSelect.forEach((value, index) => {
      if (value.value === key) {
        this.legalHoldRulesTypeToSelect.splice(index, 1);
      }
    });
  }

  isValid(): boolean {
    let isValid = true;
    if (this.componentsReferences.length === 0) {
      isValid = false;
    } else {
      for (const comp of this.componentsReferences) {
        if (!comp.instance.form.valid) {
          isValid = false;
          break;
        }
      }
    }
    return isValid;
  }

  retrieveRules(): any[] {
    const array = [];
    for (const comp of this.componentsReferences) {
      const values = comp.instance.form.getRawValue();
      const val = this.getValue(values);
      array.push({property: values.property, value: val});
    }
    return array;
  }

  getValue(value: any): any {
    if (!!value.value) {
      return value.value;
    } else {
      const start = value.rangePicker.start;
      const end = value.rangePicker.end;
      return start.getTime() + '_' + end.getTime();
    }
  }
}
