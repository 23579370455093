export enum RetentionMethod {
  CREATION_DATE = 'Creation Date',
  MODIFICATION_DATE = 'Modification Date',
  NOT_CLASSIFIED = 'Not Classified',
  CUSTOM_EVENT = 'Custom Event'
}

export enum PolicyType {
  STANDARD = 'STANDARD',
  FINRA = 'FINRA'
}

export const codesExportFileHeaders = ['Policy code', 'Retention time', 'Record category', 'Custom Offset', 'Country', 'Function', 'Sub Function', 'Event', 'Event Label', 'Description'];
export const retentionCodeKeys = ['code', 'retentionTime', 'recordCategory', 'customOffset', 'country', 'function', 'subFunction', 'event', 'eventLabel', 'description'];

export const DEFAULT_RETENTIONS_ITEMS_PAGE_NUMBER = 10;
