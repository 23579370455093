import {Component, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-no-policy-assigned',
  templateUrl: './no-policy-assigned.component.html',
  styleUrls: ['./no-policy-assigned.component.scss']
})
export class NoPolicyAssignedComponent implements OnInit {

  @Input() openAssignerSubject: Subject<boolean>;
  @Input() isError: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

  open(): void {
    this.openAssignerSubject.next(true);
  }

}
