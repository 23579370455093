import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {LibrariesRoutingModule} from './libraries-routing.module';
import {PendingLibrariesComponent} from './pending/pending-libraries.component';
import {ActiveLibrariesComponent} from './active/active-libraries.component';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatPaginatorI18nService} from '../../services/PaginatorI18nService';
import {LibrariesComponent} from './libraries.component';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {LibraryTagSelectorComponent} from '../../components/library-tag-selector/library-tag-selector.component';
import {SharedModule} from '../shared/shared.module';
import {LibrariesDetailComponent} from './detail/libraries-detail.component';
import {DocumentClassStatesTableComponent} from '../../components/document-class-states-table/document-class-states-table.component';
import {ActivationThresholdComponent} from '../../components/activation-threshold/activation-threshold.component';
import {AssignedRetentionsTableComponent} from '../../components/assigned-retentions-table/assigned-retentions-table.component';
import {PolicyAssignerComponent} from './policy-assigner/policy-assigner.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatStepperModule} from '@angular/material/stepper';
import {ReactiveFormsModule} from '@angular/forms';
import {LibraryCalculateMessageComponent} from '../../components/library-configuration-status/calculate/library-calculate-message.component';
import {FolderPickerComponent} from '../../components/folder-picker/folder-picker.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {NoPolicyAssignedComponent} from '../../components/no-policy-assigned/no-policy-assigned.component';
import { AllCriteriaMetComponent } from '../../components/library-configuration-status/all-criteria-met/all-criteria-met.component';
import {ConfigurationErrorComponent} from '../../components/library-configuration-status/error/configuration-error.component';
import {NoPolicyAtRootComponent} from '../../components/library-configuration-status/no-policy-at-root/no-policy-at-root.component';

@NgModule({
  declarations: [PendingLibrariesComponent,
    ActiveLibrariesComponent,
    LibrariesComponent,
    LibraryTagSelectorComponent,
    LibrariesDetailComponent,
    DocumentClassStatesTableComponent,
    ActivationThresholdComponent,
    AssignedRetentionsTableComponent,
    PolicyAssignerComponent,
    LibraryCalculateMessageComponent,
    FolderPickerComponent,
    NoPolicyAssignedComponent,
    AllCriteriaMetComponent,
    ConfigurationErrorComponent,
    NoPolicyAtRootComponent],
    imports: [
        CommonModule,
        SharedModule,
        LibrariesRoutingModule,
        MatCardModule,
        MatTableModule,
        TranslateModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        FlexModule,
        ExtendedModule,
        MatPaginatorModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatDialogModule,
        MatStepperModule,
        ReactiveFormsModule,
        MatGridListModule,
        MatToolbarModule,
        MatListModule,
      TranslateModule.forChild({
        extend: true
      })
    ],
  exports: [
    DocumentClassStatesTableComponent
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorI18nService,
    },
  ]
})
export class LibrariesModule {
}
