import {Component, Input} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {DocumentClassState} from '../../models/library.model';
import {LibraryService} from '../../services/library.service';

@Component({
  selector: 'app-document-class-states-table',
  templateUrl: './document-class-states-table.component.html',
  styleUrls: ['./document-class-states-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class DocumentClassStatesTableComponent {

  @Input() dataSource;
  @Input() libraryId;
  columnsToDisplay = ['displayName', 'status', 'settings', 'expand'];
  expandedElement: DocumentClassState | null;

  constructor(private libraryService: LibraryService) {
  }

  getColumnName(column: string): string {
    return column !== 'expand' && column !== 'settings' ? column : null;
  }

  getConfigClassListUrl(): string {
    return this.libraryService.getConfigClassListUrl(this.libraryId);
  }
}

