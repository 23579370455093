import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {ApiVersion} from '../constants/apis';
import {User} from '../models/user.model';
import {Observable, Subject, Subscription} from 'rxjs';
import {AuthService} from './auth.service';
import {DialogService} from './dialog.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {UserClassification} from '../constants/user-constants';
import {DomainFeature} from '../constants/domain-feature-constants';
import {Callback} from '../models/callback.model';


@Injectable({
  providedIn: 'root'
})
export class UserService extends AbstractService {

  private user$: User;
  public userSubject = new Subject<User>();
  private authSubscription: Subscription = new Subscription();

  constructor(private authService: AuthService,
              private router: Router,
              private http: HttpClient,
              private dialogService: DialogService) {
    super();
    this.authSubscription = this.authService.profileSubject.subscribe((evt) => {
      if (evt.profile) {
        this.getCurrentUser().subscribe({
          next: async resp => {
            this.user = resp;
            await this.route(evt.callback);
          },
          error: error => this.dialogService.error(error.error.message)
        });
      }
    });
  }

  getCurrentUser(): Observable<User> {
    const url = this.toUrl('library', 'currentUser', ApiVersion.V1);
    return this.http.get<User>(url);
  }

  set user(value: User) {
    this.user$ = value;
    this.userSubject.next(value);
  }

  get user(): User {
    return this.user$;
  }

  async route(callback: Callback): Promise<void> {
    if (callback) {
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([callback.url], {queryParams: callback.params});
      });

    } else {
      if (this.isAuditor()) {
        await this.router.navigate(['audit/']);
      } else {
        await this.router.navigate(['libraries/']);
      }
    }
  }

  public isSuperAdmin(): boolean {
    if (this.user) {
      return (this.user.userClassification === UserClassification.AODOCS_SUPER_ADMIN)
        || (this.user.userClassification === UserClassification.RETENTION_ADMIN);
    } else {
      return false;
    }
  }

  public isAuditor(): boolean {
    return (this.user && this.user.userClassification === UserClassification.AUDITOR);
  }

  public isSimpleUser(): boolean {
    return (this.user && this.user.userClassification === UserClassification.USER);
  }

  public isFinra(): boolean {
    return this.isDomainFeature(DomainFeature.FINRA);
  }

  private isDomainFeature(feature: DomainFeature): boolean {
    if (!this.user.features) {
      return false;
    }
    return this.user.features.includes(feature);
  }
}
