<button mat-icon-button class="close-button" [mat-dialog-close]="false">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h2 mat-dialog-title class="header">{{"policies.creator.title" | translate}}</h2>
<mat-dialog-content id="creation-dialog-content" class="mat-typography">
  <div class="spinner-container" *ngIf="loading$ | async">
    <mat-spinner></mat-spinner>
  </div>
  <section [hidden]="loading$ | async">
    <mat-horizontal-stepper id="retention-creator-stepper" labelPosition="bottom" linear #stepper>
      <mat-step [stepControl]="identificationFormGroup">
        <form [formGroup]="identificationFormGroup">
          <ng-template matStepLabel>{{"policies.creator.steps.identification.title"|translate}}</ng-template>
          <p class="grey-label label-margin">{{"policies.creator.steps.identification.descriptions.id"|translate}}</p>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field appearance="fill" fxFlex="48">
              <mat-label>{{"policies.creator.steps.identification.labels.policyCode"|translate}}</mat-label>
              <input matInput formControlName="code" required autocomplete="off">
              <small class="red" *ngIf="identificationFormGroup.get('code')?.errors?.forbiddenName">
                Policy code should not contain special characters.
              </small>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="48">
              <mat-label>{{"policies.creator.steps.identification.labels.country"|translate}}</mat-label>
              <input matInput formControlName="country" required autocomplete="off">
              <small class="red" *ngIf="identificationFormGroup.get('country')?.errors?.forbiddenName">
                Policy Country should not contain special characters.
              </small>
            </mat-form-field>
          </div>
          <p class="grey-label label-margin">{{"policies.creator.steps.identification.descriptions.optional"|translate}}</p>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field appearance="fill" fxFlex="48">
              <mat-label>{{"policies.creator.steps.identification.labels.function"|translate}}</mat-label>
              <input matInput formControlName="function" autocomplete="off">
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="48">
              <mat-label>{{"policies.creator.steps.identification.labels.sub_function"|translate}}</mat-label>
              <input matInput formControlName="subFunction" autocomplete="off">
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-form-field appearance="fill" fxFlex="48">
              <mat-label>{{"policies.creator.steps.identification.labels.category"|translate}}</mat-label>
              <input matInput formControlName="category" autocomplete="off">
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center" class="description-last-margin">
            <mat-form-field appearance="fill" fxFlex="100">
              <mat-label>{{"policies.creator.steps.identification.labels.description"|translate}}</mat-label>
              <input matInput formControlName="description" autocomplete="off">
            </mat-form-field>
          </div>
          <section mat-dialog-actions align="end">
            <section fxFlex fxLayout="row" fxLayoutAlign="end center">
              <button mat-button class="blue-label fw-500" [disabled]="!identificationFormGroup.valid"
                      matStepperNext>{{"policies.creator.next"|translate}}</button>
            </section>
          </section>
        </form>
      </mat-step>
      <mat-step [stepControl]="setupFormGroup">
        <form [formGroup]="setupFormGroup">
          <ng-template matStepLabel>{{"policies.creator.steps.setup.title"|translate}}</ng-template>
          <div fxLayout="row" fxLayoutAlign="start center">
            <p class="grey-label label-margin">{{"policies.creator.steps.setup.descriptions.method"|translate}} </p>

          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-radio-group aria-label="Select an option" formControlName="method" fxLayout="column"
                             fxLayoutAlign="space-between none"
                             (change)="methodChanged($event)">
              <mat-radio-button
                [value]="retentionMethod.CREATION_DATE">{{"policies.creator.steps.setup.labels.creation_date"|translate}}
                :
                <span
                  class="radio-description">{{"policies.creator.steps.setup.labels.creation_date_text"|translate}}</span>
              </mat-radio-button>
              <mat-radio-button
                [value]="retentionMethod.MODIFICATION_DATE">{{"policies.creator.steps.setup.labels.modification_date"|translate}}
                :
                <span
                  class="radio-description">{{"policies.creator.steps.setup.labels.modification_date_text"|translate}}</span>
              </mat-radio-button>
              <mat-radio-button
                [value]="retentionMethod.NOT_CLASSIFIED">{{"policies.creator.steps.setup.labels.not_classified"|translate}}
                :
                <span
                  class="radio-description">{{"policies.creator.steps.setup.labels.not_classified_text"|translate}}</span>
              </mat-radio-button>
              <mat-radio-button
                [value]="retentionMethod.CUSTOM_EVENT">{{"policies.creator.steps.setup.labels.custom_event"|translate}}
                :
                <span
                  class="radio-description">{{"policies.creator.steps.setup.labels.custom_event_text"|translate}}</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <p class="grey-label label-margin">{{"policies.creator.steps.setup.descriptions.period"|translate}}</p>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="48">
              <mat-form-field appearance="fill">
                <mat-label>{{"policies.creator.steps.setup.labels.time"|translate}}</mat-label>
                <input matInput formControlName="time" type="number" min="-1" required>
              </mat-form-field>
              <span class="year-margin">{{"policies.creator.steps.setup.labels.years"|translate}}</span></div>
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="48">
              <mat-form-field appearance="fill">
                <mat-label>{{"policies.creator.steps.setup.labels.offset"|translate}}</mat-label>
                <input matInput formControlName="offset" type="number"  min="0">
              </mat-form-field>
              <div fxLayout="row" fxLayoutAlign="start" class="tooltip-container">
                <span class="year-margin">{{"policies.creator.steps.setup.labels.years"|translate}}</span>
                <mat-icon class="tooltip-icon" matTooltipClass="line-broken-tooltip" [matTooltip]="offsetToolTip()">
                  help_outline
                </mat-icon>
              </div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column" fxFlex="48">
              <p class="grey-label label-margin">{{"policies.creator.steps.setup.descriptions.event"|translate}}</p>
              <mat-form-field appearance="fill">
                <mat-label>{{"policies.creator.steps.setup.labels.event"|translate}}</mat-label>
                <input matInput formControlName="event" autocomplete="off">
              </mat-form-field>
            </div>
            <div fxLayout="column" fxFlex="48">
              <p class="grey-label label-margin">{{"policies.creator.steps.setup.descriptions.second_label"|translate}}</p>
              <mat-form-field appearance="fill">
                <mat-label>{{"policies.creator.steps.setup.labels.second_label"|translate}}</mat-label>
                <input matInput formControlName="secondaryLabel" autocomplete="off">
              </mat-form-field>
            </div>

          </div>
          <section mat-dialog-actions align="end">
            <section fxFlex fxLayout="row" fxLayoutAlign="end center">
              <button mat-button matStepperPrevious>{{"policies.creator.previous"|translate}}</button>
              <button mat-button class="blue-label fw-500" [disabled]="!setupFormGroup.valid"
                      matStepperNext>{{"policies.creator.next"|translate}}</button>
            </section>
          </section>
        </form>
      </mat-step>
      <mat-step [stepControl]="optionsFormGroup">
        <form [formGroup]="optionsFormGroup">
          <ng-template matStepLabel>{{"policies.creator.steps.options.title"|translate}}</ng-template>
          <p class="grey-label label-margin">{{"policies.creator.steps.options.descriptions.activate"|translate}}</p>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-slide-toggle [(ngModel)]="isActive" formControlName="active">
              {{"policies.creator.steps.options.labels.active"|translate}}
              {{"policies.creator.steps.options.labels." + getActiveText() | translate}}
            </mat-slide-toggle>
          </div>
          <p class="grey-label label-margin">{{"policies.creator.steps.options.descriptions.rules"|translate}}</p>
          <div class="metadata-container" fxLayout="row" fxLayoutAlign="space-between start">
            <div formArrayName="metadata" fxFlex="100">
              <div *ngFor="let data of metadata.controls; let i = index; last as isLast;"
                   fxLayout="row" fxLayoutAlign="space-between center"
                   [formGroupName]="i">
                <mat-form-field appearance="fill" fxFlex="45">
                  <mat-label>{{"policies.creator.steps.options.labels.property"|translate}}</mat-label>
                  <input matInput formControlName="property" placeholder="Property" autocomplete="off">
                </mat-form-field>
                <mat-form-field appearance="fill" fxFlex="45">
                  <mat-label>{{"policies.creator.steps.options.labels.value"|translate}}</mat-label>
                  <input matInput formControlName="value" placeholder="Value" autocomplete="off">
                </mat-form-field>
                <div fxLayout="row" fxFlex.lt-sm="100" fxLayoutAlign.lt-sm="end center">
                  <button *ngIf="isLast" mat-icon-button aria-label="Add metadata" type="button" (click)="addMetadata()"
                          [disabled]="!data.valid || metadata.errors !== null">
                    <mat-icon>add</mat-icon>
                  </button>
                  <button mat-icon-button aria-label="Remove metadata" *ngIf="!isLast" type="button" (click)="removeMetadata(i)">
                    <mat-icon>remove</mat-icon>
                  </button>
                </div>

              </div>
            </div>
          </div>
          <section mat-dialog-actions align="end">
            <section fxFlex fxLayout="row" fxLayoutAlign="end center">
              <button mat-button matStepperPrevious>{{"policies.creator.previous"|translate}}</button>
              <button mat-button class="blue-label fw-500" type="button" (click)="create()" [disabled]="!optionsFormGroup.valid">
                {{"policies.creator.create"|translate}}
              </button>
            </section>
          </section>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </section>
</mat-dialog-content>

