<section *ngIf="profile; else notSignedIn">
  <img [matMenuTriggerFor]="appMenu" [src]="profile.picture" alt="profile"
       class="mat-fab profile">
  <mat-menu #appMenu>
    <section class="profile-details" fxLayout="column">
      <img [src]="profile.picture" alt="profile">
      <p>{{profile.name}}</p>
      <p class="email">{{profile.email}}</p>
    </section>
    <mat-divider></mat-divider>
    <a (click)="logout()" mat-menu-item>Logout</a>
  </mat-menu>
</section>
<ng-template #notSignedIn>

  <section class="login-section">
    <div class="mat-elevation-z2 sign-in-button" [class.large_width] = "googleLoading">
      <ao-google-login
        [auto]="autoEnabled"
        [clientId]="clientId"
        [scopes]="scopes"
        (loginSuccess)="googleLoginSuccess($event)"
        (loginError)="loginError()"
        [disabled]="googleLoading"
        display-mode="content"
        authentication-mode="token"
        id="gLogin">
        <div
          class="button-header"
          (click)="googleAuth()"
          [class.disabled]="googleLoading">
          <mat-progress-spinner *ngIf="googleLoading" mode="indeterminate" [diameter]="24"></mat-progress-spinner>
          <img alt="logo" class="logo-size" src="../../../assets/img/google_icon.svg"/>
          <span>Sign in with Google</span>
          <button mat-icon-button color="primary" [disabled]="googleLoading">
            <mat-icon>arrow_forward</mat-icon>
          </button>
        </div>
      </ao-google-login>
    </div>
  </section>

</ng-template>
