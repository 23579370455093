import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {RetentionImportStatistics} from "../../models/retention-api-response";

@Component({
  selector: 'app-csv-import-statistics',
  templateUrl: './csv-import-statistics.component.html',
  styleUrls: ['./csv-import-statistics.component.scss']
})
export class CsvImportStatisticsComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: RetentionImportStatistics) { }
}
