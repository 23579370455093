import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {DialogService} from '../../../../services/dialog.service';
import {CustomSnackBarService} from '../../../../services/custom-snackbar.service';
import {BehaviorSubject} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {PurgeLibraryJob} from '../../../../models/reporting.model';
import {LibraryService} from '../../../../services/library.service';
import {ReportingService} from '../../../../services/reporting.service';
import {MatPaginator} from '@angular/material/paginator';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-reports-deleted-docs',
  templateUrl: './reports-deleted-docs-details.component.html',
  styleUrls: ['./reports-deleted-docs-details.component.scss']
})
export class ReportsDeletedDocsDetailsComponent implements OnInit, AfterViewInit {

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public dataSource: MatTableDataSource<PurgeLibraryJob>;
  public columns = ['libraryId', 'executionDate', 'deletedDocs', 'errors', 'export'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public deletionBatchId: string;

  constructor(private activatedRoute: ActivatedRoute,
              private libraryService: LibraryService,
              private reportingService: ReportingService,
              private dialogService: DialogService,
              protected router: Router,
              private customSnackBarService: CustomSnackBarService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.deletionBatchId = this.activatedRoute.snapshot.params.id;
    this.listLibraryPurgeJobsUnderDomainPurgeJob(this.deletionBatchId);
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  displayedColumns(): string[] {
    return this.columns;
  }

  listLibraryPurgeJobsUnderDomainPurgeJob(deletionBatchId: string): void {
    this.loadingSubject.next(true);
    this.reportingService.listLibraryPurgeJobsUnderDomainPurgeJob(deletionBatchId)
      .subscribe({
        next: (resp) => {
          this.dataSource.data = resp.items;
          this.loadingSubject.next(false);
        },
        error: (error) => {
          this.loadingSubject.next(false);
          this.dialogService.error(error?.error?.error?.message ?? error.message);
        }
      });
  }

  exportDeletedDocsForLibraryJob(purgeLibraryJob: PurgeLibraryJob): void {
    this.reportingService.exportDeletedDocsForLibraryJob(this.deletionBatchId, purgeLibraryJob.id)
      .subscribe({
        next: () => this.customSnackBarService.openSuccessSnackbar(this.translate.instant('snackBarMessage.exportStarted')),
        error: error => this.dialogService.error(error?.error?.error?.message ?? error.message)
      });
  }

  getLibraryIdFromPurgeLibraryJob(purgeLibraryJob: PurgeLibraryJob): string {
    return purgeLibraryJob.id.split('_')[0];
  }

  gotoPath(): void {
    const to = 'reports/deleted-documents';
    this.router.navigate([to]);
  }

}
