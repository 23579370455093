import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {LibraryService} from '../../../services/library.service';
import {DialogService} from '../../../services/dialog.service';
import {Router} from '@angular/router';
import {SearchService} from '../../../services/search.service';
import {SearchData} from '../../../models/search-data-model';
import {AODocsDocumentEntity, CrossLibrarySearchResult} from '../../../models/cross-library-search-result.model';
import {PolicyType} from '../../../constants/retention-constants';
import {environment} from '../../../../environments/environment';
import {UserService} from '../../../services/user.service';
import {SearchParentJobStatus} from '../../../constants/cross-library-search-constants';
import {MatDialog} from '@angular/material/dialog';
import {
  RetainedDocumentAttachmentsDialogComponent
} from '../../../components/retained-document-attachments-dialog/retained-document-attachments-dialog.component';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-retained-documents',
  templateUrl: './retained-documents.component.html',
  styleUrls: ['./retained-documents.component.scss']
})
export class RetainedDocumentsComponent implements OnInit, AfterViewInit {

  public searchForm = new FormControl();
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public dataSource: MatTableDataSource<AODocsDocumentEntity>;
  public columns = ['title', 'library', 'class', 'creationDate', 'creator', 'download', 'link'];
  private finraLibraries = [];
  private searchDataList = [];
  private searchParentJobId = null;
  private load = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(protected libraryService: LibraryService,
              protected searchService: SearchService,
              protected userService: UserService,
              private dialog: MatDialog,
              private iconRegistry: MatIconRegistry,
              private sanitizer: DomSanitizer,
              private dialogService: DialogService,
              protected router: Router) {
    iconRegistry.addSvgIcon('see_attachments', sanitizer.bypassSecurityTrustResourceUrl('assets/img/see-attachments.svg'));
  }

  ngOnInit(): void {
    this.loading$.subscribe(event => this.load = event);
    this.dataSource = new MatTableDataSource<AODocsDocumentEntity>();
    this.getFinraLibraries();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  getFinraLibraries(): void {
    this.loadingSubject.next(true);
    this.libraryService.getLibrariesByPolicyType(PolicyType.FINRA).subscribe({
      next: (resp) => {
        this.finraLibraries = resp.libraries;
        this.initSearchData();
        this.loadingSubject.next(false);
      },
      error: (error) => {
        this.dialogService.error(error?.error?.error?.message ?? error.message);
        this.loadingSubject.next(false);
      }
    });
  }

  search(): void {
    if (!this.load && this.searchForm.value && this.searchForm.value.length >= 3) {
      this.loadingSubject.next(true);
      const searchQuery = decodeURIComponent(this.searchForm.value);
      this.searchService.search(searchQuery, this.searchDataList).subscribe({
        next: (resp) => {
          this.searchParentJobId = resp.jobId;
          this.startPoll();
        }, error: (error) => this.dialogService.error(error?.error?.error?.message ?? error.message)
      });
    }
  }

  async startPoll(): Promise<void> {
    const fetchResponse = await this.searchService.poll(this.searchParentJobId).toPromise();
    if (fetchResponse.status !== SearchParentJobStatus.DONE) {
      setTimeout(() => this.startPoll(), 2000);
    } else {
      this.displayResult(fetchResponse);
    }
  }

  displayResult(result: CrossLibrarySearchResult): void {
    this.dataSource.data = result.result;
    this.loadingSubject.next(false);
  }

  initSearchData(): void {
    this.searchDataList = [];
    for (const libraryId of this.finraLibraries) {
      const searchData: SearchData = {libraryId, nextPageToken: ''};
      this.searchDataList.push(searchData);
    }
  }

  getDocumentUrl(document: AODocsDocumentEntity): string {
    const aodocsUrl = environment.aodocsUrl;
    const domain = this.userService.user.domain;
    return aodocsUrl + '?aodocs-domain=' + domain + '#Menu_viewDoc/LibraryId_' + document.libraryId + '/DocumentId_' + document.id;
  }

  displayAttachments(element: AODocsDocumentEntity): void {
    this.dialog.open(RetainedDocumentAttachmentsDialogComponent, {
      data: {document: element},
      width: '500px'
    }).afterClosed().subscribe((choice) => {
      if (choice) {
        console.log('closed');
      }
    });
  }

  isSearchQueryCompatible(): boolean {
    return this.searchForm.value && this.searchForm.value.length >= 3;
  }

  hasNoAttachments(element: AODocsDocumentEntity): boolean {
    return !element.attachments;
  }

  displayedColumns(): string[] {
    return this.columns;
  }
}
