import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuditLogsListComponent} from './logs/list/audit-logs-list.component';
import {RetainedDocumentsComponent} from './retained-documents/retained-documents.component';


const routes: Routes = [
  {
    path: 'logs',
    component: AuditLogsListComponent
  },
  {
    path: 'retained-documents',
    component: RetainedDocumentsComponent
  },
  {
    path: '', redirectTo: '/audit/logs', pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuditRoutingModule {
}
