import {Component, Input, OnInit} from '@angular/core';
import {LegalHoldRulesType} from '../../constants/legal-hold-constants';
import {InputType} from '../../constants/input-types-constants';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {LegalHoldCreatorComponent} from '../../modules/holds/legal-hold/creator/legal-hold-creator.component';
import {MatOptionSelectionChange} from '@angular/material/core';

@Component({
  selector: 'app-legal-hold-rule-field',
  templateUrl: './legal-hold-rule-field.component.html',
  styleUrls: ['./legal-hold-rule-field.component.scss']
})
export class LegalHoldRuleFieldComponent implements OnInit {

  public type: InputType;
  public form: FormGroup;
  public uniqueKey: number;
  public parentRef: LegalHoldCreatorComponent;
  public legalHoldRulesTypeToSelect$;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      property: ['', Validators.required],
      value: new FormControl(),
      rangePicker: new FormGroup({
        start: new FormControl(),
        end: new FormControl()
      })
    });
  }

  ngOnInit(): void {
  }

  isTextField(): boolean {
    return this.type === InputType.TEXT_FIELD;
  }

  isDatePicker(): boolean {
    return this.type === InputType.DATE_PICKER;
  }

  isEmail(): boolean {
    return this.type === InputType.EMAIL;
  }

  @Input() set legalHoldRulesTypeToSelect(value: ({ viewValue: string; value: LegalHoldRulesType })[]) {
    this.legalHoldRulesTypeToSelect$ = value;
  }

  propertySelected($event: MatOptionSelectionChange): void {
    if ($event.isUserInput) {
      switch ($event.source.value) {
        case LegalHoldRulesType.CLASS_NAME:
        case LegalHoldRulesType.LIBRARY_NAME:
        case LegalHoldRulesType.TITLE:
          this.type = InputType.TEXT_FIELD;
          break;
        case LegalHoldRulesType.INITIAL_AUTHOR:
        case LegalHoldRulesType.UPDATE_AUTHOR:
          this.type = InputType.EMAIL;
          break;
        case LegalHoldRulesType.CREATION_DATE:
        case LegalHoldRulesType.MODIFICATION_DATE:
          this.type = InputType.DATE_PICKER;
          break;
        default:
          this.type = InputType.TEXT_FIELD;
          break;
      }
    }
  }

  remove(): void {
    this.parentRef.remove(this.uniqueKey);
  }

}

