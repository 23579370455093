import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchInputComponent} from './search-input/search-input.component';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ChipsComponent} from '../../components/chips/chips.component';
import {MatChipsModule} from '@angular/material/chips';
import {ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {FlexModule} from '@angular/flex-layout';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {SuccessSnackbarComponent} from './success-snackbar/success-snackbar.component';



@NgModule({
  declarations: [
    SearchInputComponent,
    SuccessSnackbarComponent,
    SearchInputComponent,
    ChipsComponent
  ],
  exports: [
    SearchInputComponent,
    ChipsComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    FlexModule,
    MatInputModule,
    MatChipsModule,
    ReactiveFormsModule,
    MatAutocompleteModule
  ]
})
export class SharedModule {
}
