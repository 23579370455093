import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {ApiVersion} from '../constants/apis';
import {AuditLog, AuditLogFilterTypes, AuditLogFilterValues} from '../models/audit-log.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuditLogService extends AbstractService {

  constructor(private http: HttpClient) {
    super();
  }

  list(limit: number, offset: number, filters: AuditLogFilterValues): Observable<{ items: AuditLog[] }> {
    const url = this.toUrl('auditLog', 'list/' + limit + '/' + offset, ApiVersion.V1);
    const params = this.convertAuditLogFiltersToHttpParams(filters);
    return this.http.get<{ items: AuditLog[] }>(url, {params});
  }

  count(filters: AuditLogFilterValues): Observable<{ auditLogCount: number }> {
    const url = this.toUrl('auditLog', 'count', ApiVersion.V1);
    const params = this.convertAuditLogFiltersToHttpParams(filters);
    return this.http.get<{ auditLogCount: number }>(url, {params});
  }

  getFieldsTypes(fieldType: string): Observable<AuditLogFilterTypes> {
    const url = this.toUrl('auditLog', 'getFieldsTypes/' + fieldType, ApiVersion.V1);
    return this.http.get<AuditLogFilterTypes>(url);
  }

  convertAuditLogFiltersToHttpParams(filters: AuditLogFilterValues): HttpParams {
    let httpParams = new HttpParams();
    Object.keys(filters).forEach(k => {
      if (filters[k]) {
        httpParams = httpParams.set(k, filters[k]);
      }
    });

    return httpParams;
  }
}
