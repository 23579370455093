import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {PoliciesCreatorComponent} from '../policies-creator.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UserService} from '../../../../services/user.service';
import {AdminService} from '../../../../services/admin.service';
import {Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {RetentionService} from '../../../../services/retention.service';
import {DialogService} from '../../../../services/dialog.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-finra-policies-creator',
  templateUrl: './finra-policies-creator.component.html',
  styleUrls: ['../policies-creator.component.scss', './finra-policies-creator.component.scss']
})
export class FinraPoliciesCreatorComponent extends PoliciesCreatorComponent implements OnInit {

  constructor(dialog: MatDialog,
              userService: UserService,
              adminService: AdminService,
              router: Router,
              dialogRef: MatDialogRef<PoliciesCreatorComponent>,
              formBuilder: FormBuilder,
              cdr: ChangeDetectorRef,
              retentionService: RetentionService,
              dialogService: DialogService,
              translate: TranslateService) {
    super(dialog, userService, adminService, router, dialogRef, formBuilder, cdr, retentionService, dialogService, translate);
  }

  ngOnInit(): void {
    this.isFinra = true;
    this.initForms();
  }

}
