export const environment = {
  baseUrl: 'https://retention.uat-usc1.aodocs.app',
  apiUrl: '/_ah/api/',
  aodocsUrl: 'https://ao-docs-staging.appspot.com/',
  clientId: '336465149492-5cfkmf391gtfvqudfqsvp4fulf1c562o',
  gcsAPI: 'https://storage.googleapis.com/upload/storage/v1/b/{BUCKET}/o',
  ossApi: 'https://aodocs-object-storage-staging.appspot.com/api/storage',
  version: '4.0.0',
  production: false
};
