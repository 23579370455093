import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {i18nLangs} from '../constants/i18n-langs';

@Injectable({
  providedIn: 'root'
})
export class I18nService {

  constructor(public translate: TranslateService) {
  }

  initTranslateService(): void {
    this.translate.addLangs(i18nLangs);
    this.translate.setDefaultLang('en');
    this.translate.use(this.getLangToUse());
  }

  getLangToUse(): string {
    let shortLang = navigator.language;
    if (shortLang.indexOf('-') !== -1) {
      shortLang = shortLang.split('-')[0];
    }
    if (shortLang.indexOf('_') !== -1) {
      shortLang = shortLang.split('_')[0];
    }
    return i18nLangs.includes(shortLang) ? shortLang : 'en';
  }
}
