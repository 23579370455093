import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {ApiVersion} from '../constants/apis';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobService extends AbstractService {

  constructor(private http: HttpClient) {
    super();
  }

  recalculateRetentions(libraryId: string, libraryType: string): Observable<void> {
    const url = this.toUrl('job', 'recalculateRetentions/library/' + libraryId + '?libraryTpe=' + libraryType, ApiVersion.V1);
    return this.http.post<void>(url, {libraryType});
  }
}
