import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LibraryService} from '../../services/library.service';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {DEFAULT_LIBRARIES_ITEMS_PAGE_NUMBER, LibraryTagFilter} from '../../constants/library-constants';
import {LibrariesDataSource} from '../../models/libraries-data-source';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {DialogService} from '../../services/dialog.service';
import {Router} from '@angular/router';

@Component({
  templateUrl: './libraries.component.html',
  styleUrls: ['./libraries.component.scss']
})
export class LibrariesComponent implements OnInit, AfterViewInit, OnDestroy {

  protected libraryListFilter: LibraryTagFilter;
  public loaded = false;
  public dataSource: LibrariesDataSource;
  public displayedColumns: string[] = ['displayName', 'libraryType', 'configurationStatus', 'numberOfRetentionBindings', 'edit', 'link'];
  public libraryActiveTab = false;
  public query: string;
  public pageTitle: string;
  public librariesCount: number;
  public prevPageSize: number = DEFAULT_LIBRARIES_ITEMS_PAGE_NUMBER
  public searchValueSubject: Subject<string> = new Subject<string>();
  public filterValueSubject: Subject<LibraryTagFilter> = new Subject<LibraryTagFilter>();
  public searchClearSubject: Subject<boolean> = new Subject();
  public filterClearSubject: Subject<boolean> = new Subject();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(protected libraryService: LibraryService,
              private dialogService: DialogService,
              protected router: Router) {
    this.dataSource = new LibrariesDataSource(this.libraryService, this.dialogService);
  }

  ngOnInit(): void {
    this.loaded = true;
    this.initFilterValueSubject();
    this.initSearchValueSubject();
    this.countLibraries();
    this.dataSource.loadLibrariesPage(0, this.libraryListFilter);
  }

  ngAfterViewInit(): void {
    this.initPaginator();
  }

  ngOnDestroy(): void {
    this.searchValueSubject.unsubscribe();
    this.filterValueSubject.unsubscribe();
    this.searchClearSubject.unsubscribe();
    this.filterClearSubject.unsubscribe();
  }

  edit(libraryId: string): void {
    const to = this.libraryActiveTab ? 'libraries/active/library/' + libraryId : 'libraries/pending/library/' + libraryId;
    this.router.navigate([to]);
  }

  refresh(): void {
    this.query = null;
    this.searchClearSubject.next(true);
    this.filterClearSubject.next(true);
    this.initTable();
  }

  getLibrarySettingsUrl(libraryId: string): string {
    return this.libraryService.getLibrarySettingsUrl(libraryId);
  }

  protected initTable(): void {
    this.paginator.pageIndex = 0;
    this.countLibraries();
    this.dataSource.init(this.libraryListFilter, this.query, 0, this.paginator.pageSize);
  }

  protected countLibraries(): void {
    this.libraryService.countLibraries(this.libraryListFilter, this.query).subscribe({
      next: resp => this.librariesCount = resp.libraryCount,
      error: error => this.dialogService.error(error?.error?.error?.message ?? error.message)
    });
  }

  initPaginator(): void {
    this.paginator.page
      .pipe(
        tap((event: PageEvent) => {
          if (this.prevPageSize != event.pageSize) {
            // items pre page are change -> table is initialized again
            this.initTable();
            this.prevPageSize = event.pageSize;
          } else {
            this.dataSource.loadLibrariesPage(event.pageIndex, this.libraryListFilter,
              this.query,
              this.paginator.pageIndex,
              this.paginator.pageSize);
          }
        })
      ).subscribe();
  }

  initSearchValueSubject(): void {
    this.searchValueSubject.pipe(
      debounceTime(300),
      distinctUntilChanged()).subscribe((value) => {
      this.query = value;
      this.initTable();
    });
  }

  initFilterValueSubject(): void {
    this.filterValueSubject.pipe(
      debounceTime(300),
      distinctUntilChanged()).subscribe((value) => {
      this.libraryListFilter = value;
      this.initTable();
    });
  }

  public getPageSize(): number {
    return DEFAULT_LIBRARIES_ITEMS_PAGE_NUMBER;
  }
}
