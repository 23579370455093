import {Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import {EMPTY, Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {TOKEN_CREATION_TIME} from '../constants/local-storage-constants';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

  readonly tokenLife: number = 3540; // 59 minutes

  constructor(private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const now = Math.floor(Date.now() / 1000);
    const tokenCreationTime = parseInt(localStorage[TOKEN_CREATION_TIME]);
    if (now - tokenCreationTime > this.tokenLife) {
      this.authService.logout();
      return EMPTY;
    }
    const token = this.authService.getToken();
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      },
    });
    return next.handle(req);
  }

}
