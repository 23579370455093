<table mat-table [dataSource]="dataSource" multiTemplateDataRows>
  <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
    <th mat-header-cell *matHeaderCellDef> {{getColumnName(column) ? ('libraries.edit.status.table.'+getColumnName(column)|translate) : ''}} </th>
    <td mat-cell *matCellDef="let element">
      <span *ngIf="column=='displayName'">{{element[column]}}</span>
      <section *ngIf="column=='status'">
        <section fxLayout="row" fxLayoutAlign="start center" *ngIf="element.unmetRequirements" class="status-column">
          <mat-icon class="red">clear</mat-icon>
          <span class="grey-label">{{element.unmetRequirements.length}} {{'libraries.edit.status.table.missing'|translate}}</span>
        </section>
        <section fxLayout="row" fxLayoutAlign="start center" *ngIf="!element.unmetRequirements" class="status-column">
          <mat-icon class="green">done</mat-icon>
          <span class="grey-label"> All criteria met!</span>
        </section>
      </section>

      <a mat-button mat-icon-button *ngIf="column=='settings'" [href]="getConfigClassListUrl()"
         target="_blank" class="table-button"
         matTooltip="{{'libraries.edit.status.table.tooltips.libraryLink' | translate}}">
        <mat-icon>settings</mat-icon>
      </a>
      <button mat-button mat-icon-button class="table-button"
              *ngIf="column=='expand' && element.unmetRequirements" (click)="expandedElement = expandedElement === element ? null : element"
              matTooltip="{{'libraries.edit.status.table.tooltips.expand' | translate}}">
        <mat-icon>expand_more</mat-icon>
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
      <div class="element-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <ul class="unmet-reqs" *ngIf="element.unmetRequirements">
          <li *ngFor="let requirement of element.unmetRequirements" fxLayout="row" fxLayoutAlign="start center" class="status-column">
            <mat-icon class="red">clear</mat-icon>
            <span class="grey-label">{{requirement}}</span>
          </li>
        </ul>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
      class="element-row"
      [class.expanded-row]="expandedElement === element">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
</table>
