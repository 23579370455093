import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {PoliciesEditorComponent} from '../policies-editor.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Policy} from '../../../../models/retention.model';
import {FormBuilder} from '@angular/forms';
import {RetentionService} from '../../../../services/retention.service';
import {DialogService} from '../../../../services/dialog.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-standard-policies-editor',
  templateUrl: './standard-policies-editor.component.html',
  styleUrls: ['./standard-policies-editor.component.scss', '../policies-editor.component.scss']
})
export class StandardPoliciesEditorComponent extends PoliciesEditorComponent implements OnInit {

  public constructor(@Inject(MAT_DIALOG_DATA) data: Policy,
                     dialogRef: MatDialogRef<PoliciesEditorComponent>,
                     formBuilder: FormBuilder,
                     cdr: ChangeDetectorRef,
                     retentionService: RetentionService,
                     dialogService: DialogService,
                     translate: TranslateService) {

    super(data, dialogRef, formBuilder, cdr, retentionService, dialogService, translate);
  }

  ngOnInit(): void {
    this.initIdentificationForm();
    this.initSetupForm();
    this.initOptionsForm();
  }

}
