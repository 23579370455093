<section id="retained-documents" class="main-content">
  <h1>{{'title.retaineddocuments' | translate}}</h1>
  <mat-card class="search-section">
    <section fxLayout="column" fxLayoutAlign="start">
    <p class="grey-label">{{'retainedDocuments.search.title' | translate}}</p>
      <mat-form-field appearance="fill" class="search-field">
        <mat-label>{{'retainedDocuments.search.label' | translate}}</mat-label>
        <input type="text" matInput [formControl]="searchForm" autocomplete="off" (keydown.enter)="search()" [disabled]="loading$ | async">
        <button type="button" mat-flat-button matSuffix (click)="search()" [disabled]="(loading$ | async) || !isSearchQueryCompatible()">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </section>
  </mat-card>

  <mat-card class="no-padding">
    <div class="spinner-container" *ngIf="loading$ | async">
      <mat-spinner></mat-spinner>
    </div>

    <section [hidden]="loading$ | async">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!-- title -->
        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef>{{'retainedDocuments.table.title' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.title}} </mat-cell>
        </ng-container>

        <!-- library -->
        <ng-container matColumnDef="library">
          <mat-header-cell *matHeaderCellDef>{{'retainedDocuments.table.library' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.libraryName}} </mat-cell>
        </ng-container>

        <!-- class -->
        <ng-container matColumnDef="class">
          <mat-header-cell *matHeaderCellDef>{{'retainedDocuments.table.class' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.className}} </mat-cell>
        </ng-container>

        <!-- creationDate -->
        <ng-container matColumnDef="creationDate">
          <mat-header-cell *matHeaderCellDef>{{'retainedDocuments.table.creationDate' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.created | date:'dd/MM/yyyy'}} </mat-cell>
        </ng-container>

        <!-- creator -->
        <ng-container matColumnDef="creator">
          <mat-header-cell *matHeaderCellDef>{{'retainedDocuments.table.creator' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.creator}} </mat-cell>
        </ng-container>

        <!-- download -->
        <ng-container matColumnDef="download">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-button (click)="displayAttachments(element)" class="grey" [disabled]="hasNoAttachments(element)">
              <mat-icon class="attachments-icon" svgIcon="see_attachments"></mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <!-- link -->
        <ng-container matColumnDef="link">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <a mat-button [href]="getDocumentUrl(element)"
               target="_blank"
               class="table-button">
              <mat-icon>launch</mat-icon>
            </a>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns()"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns();"></mat-row>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">{{"dataNotFound" | translate}}</td>
        </tr>
      </table>
    </section>
    <section [hidden]="loading$ | async">
      <mat-paginator [pageSizeOptions]="[20]"></mat-paginator>
    </section>
  </mat-card>

</section>
