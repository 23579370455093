<section fxLayout="row" fxLayoutAlign="start center" class="main">
  <section class="icon-container">
    <mat-icon>error_outline</mat-icon>
  </section>
  <section fxLayout="column" fxLayoutAlign="start" class="content-container">
    <section fxLayout="row" fxLayoutAlign="start">
      <p class="status-title">{{'libraries.edit.status.calculate.title'|translate}}</p>
      <p class="calculate-btn" (click)="recalculate()">{{'libraries.edit.status.calculate.button'|translate}}</p>
    </section>
    <section fxLayout="row" fxLayoutAlign="start">
      <p class="status-description grey-label">{{'libraries.edit.status.calculate.description'|translate}}</p>
    </section>

  </section>
</section>

