<header>
  <h1 mat-dialog-title>Update retention policy - Warning</h1>
</header>
<div mat-dialog-content>
  <h3>Be aware that all the updates to every retention code will take effect once the imminent purge domain is accomplished</h3>
  <p>
    Here you can find and download the report related to all libraries involved in the next purge<br><br>

    All changes to the retentions code assigned to all the libraries involved in the purge will take effect after the purge
  </p>
  <p *ngIf="reportDownloadUrl">
    <a target="_blank" [href]="reportDownloadUrl" >Download Report</a>
  </p>
  <p>
    Click OK to perform the update or cancel to abort the update
  </p>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button mat-dialog-close [mat-dialog-close]="false">Cancel</button>
  <button mat-button mat-dialog-close class="confirm-action-btn" [mat-dialog-close]="true">OK</button>
</div>
