<section fxLayout="row" fxLayoutAlign="start center" class="main">
  <section class="icon-container">
    <mat-icon>check</mat-icon>
  </section>
  <section fxLayout="column" fxLayoutAlign="start" class="content-container">
    <section fxLayout="row" fxLayoutAlign="start">
      <p class="status-title">{{'libraries.edit.status.ready.title'|translate}}</p>
    </section>
    <section fxLayout="row" fxLayoutAlign="start">
      <p class="status-description grey-label">{{'libraries.edit.status.ready.description'|translate}}</p>
    </section>

  </section>
</section>
