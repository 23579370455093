
export enum LegalHoldRulesType {
  LIBRARY_NAME = 'LIBRARY_NAME',
  CLASS_NAME = 'CLASS_NAME',
  TITLE = 'TITLE',
  CREATION_DATE = 'CREATION_DATE',
  MODIFICATION_DATE = 'MODIFICATION_DATE',
  INITIAL_AUTHOR = 'INITIAL_AUTHOR',
  UPDATE_AUTHOR = 'UPDATE_AUTHOR',
}


export const LegalHoldRulesTypeList = [
  {value: LegalHoldRulesType.LIBRARY_NAME, viewValue: 'Library name'},
  {value: LegalHoldRulesType.CLASS_NAME, viewValue: 'Class name'},
  {value: LegalHoldRulesType.TITLE, viewValue: 'Document title'},
  {value: LegalHoldRulesType.CREATION_DATE, viewValue: 'Creation date'},
  {value: LegalHoldRulesType.MODIFICATION_DATE, viewValue: 'Modification date'},
  {value: LegalHoldRulesType.INITIAL_AUTHOR, viewValue: 'Initial author'},
  {value: LegalHoldRulesType.UPDATE_AUTHOR, viewValue: 'Update author'}
];
