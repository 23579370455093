<section class="main-content">
  <h1>{{'title.policies' | translate}}</h1>
  <mat-card class="no-padding">
    <section class="filter-section" fxLayout="row" fxLayoutAlign="space-between center">
      <section fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxFlex="40" fxFlex.lt-md="60"
               class="search-input-container">
        <app-search-input [valueSubject]="searchValueSubject" [clearSubject]="searchClearSubject"></app-search-input>
      </section>

      <section fxLayout="row" fxLayoutAlign="end center" fxFlex="60" fxFlex.lt-md="60"
               class="search-input-container" *ngIf="superAdmin">
        <button mat-icon-button
                class="action-icon"
                matTooltip="{{'policies.list.table.tooltips.create' | translate}}"
                [matMenuTriggerFor]="menu"
                *ngIf="isFinra()">
        <mat-icon>add</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item  (click)="createStandardPolicy()">{{'policies.list.table.buttons.standard' | translate}}</button>
          <button mat-menu-item (click)="createFinraPolicy()" [disabled]="!finraSettings">{{'policies.list.table.buttons.finra' | translate}}</button>
        </mat-menu>

        <button mat-icon-button class="action-icon" (click)="createStandardPolicy()"
                matTooltip="{{'policies.list.table.tooltips.create' | translate}}"
                *ngIf="!isFinra()">
          <mat-icon>add</mat-icon>
        </button>

        <button mat-icon-button (click)="downloadCsv()" class="action-icon"
                matTooltip="{{'policies.list.table.tooltips.download' | translate}}">
          <mat-icon>download</mat-icon>
        </button>

        <button mat-icon-button class="action-icon" (click)="fileInput.click()"
                matTooltip="{{'policies.list.table.tooltips.upload' | translate}}">
          <mat-icon>upload</mat-icon>
        </button>
        <input hidden type="file" accept=".csv" #fileInput
               (change)="onFileSelect($event, fileInput)"/>

        <button mat-icon-button (click)="refresh()" class="action-icon"
                matTooltip="{{'policies.list.table.tooltips.refresh' | translate}}">
          <mat-icon>refresh</mat-icon>
        </button>
      </section>

    </section>
    <div class="spinner-container" *ngIf="(loading$ | async) || superAdmin === undefined">
      <mat-spinner></mat-spinner>
    </div>
    <section fxLayout="row" fxLayoutAlign="start">
      <table mat-table [dataSource]="dataSource" id="schedules-table" class="mat-elevation-z8"
             [hidden]="(loading$ | async) || superAdmin === undefined">
        <!-- id -->
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef><span>{{'policies.list.table.id' | translate}}</span></mat-header-cell>
          <mat-cell *matCellDef="let element" [class.deactivated]="!element.active"><span> {{element.id}} </span>
          </mat-cell>
        </ng-container>

        <!-- event -->
        <ng-container matColumnDef="event">
          <mat-header-cell *matHeaderCellDef><span>{{'policies.list.table.event' | translate}}</span></mat-header-cell>
          <mat-cell *matCellDef="let element" [class.deactivated]="!element.active"><span> {{element.event}} </span>
          </mat-cell>
        </ng-container>

        <!-- offset -->
        <ng-container matColumnDef="offset">
          <mat-header-cell *matHeaderCellDef>{{'policies.list.table.offset' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element"
                    [class.deactivated]="!element.active"><span>  {{element.customOffset}}</span></mat-cell>
        </ng-container>

        <!-- retentionTime -->
        <ng-container matColumnDef="retentionTime">
          <mat-header-cell *matHeaderCellDef><span>{{'policies.list.table.retentionTime' | translate}}</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let element"
                    [class.deactivated]="!element.active"><span>  {{element.retentionTime}}</span></mat-cell>
        </ng-container>

        <!-- metadataBased -->
        <ng-container matColumnDef="metadataBased">
          <mat-header-cell *matHeaderCellDef><span>{{'policies.list.table.metadataBased' | translate}}</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.metadataBased" [ngClass]="element.active ? 'green' : 'deactivated'">check
            </mat-icon>
          </mat-cell>
        </ng-container>

        <!-- function -->
        <ng-container matColumnDef="function">
          <mat-header-cell *matHeaderCellDef><span>{{'policies.list.table.function' | translate}}</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let element" [class.deactivated]="!element.active"><span> {{element.function}}</span>
          </mat-cell>
        </ng-container>

        <!-- finra -->
        <ng-container matColumnDef="finra">
          <mat-header-cell *matHeaderCellDef><span>{{'policies.list.table.finra' | translate}}</span></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.finra" class="green">check</mat-icon>
          </mat-cell>
        </ng-container>

        <!-- is used -->
        <ng-container matColumnDef="isUsed">
          <mat-header-cell *matHeaderCellDef><span>{{'policies.list.table.isUsed' | translate}}</span></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.retentionUsed" class="green">check</mat-icon>
            <mat-icon *ngIf="!element.retentionUsed" class="deactivated">remove</mat-icon>
            <!--mat-icon *ngIf="!element.metadataBased" class="red">close</mat-icon-->
          </mat-cell>
        </ng-container>

        <!-- view -->
        <ng-container matColumnDef="view">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-button mat-icon-button (click)="view(element)"
                    class="table-button"
                    matTooltip="{{'policies.list.table.tooltips.details' | translate}}">
              <mat-icon>visibility</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="edit">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">

            <button mat-button mat-icon-button class="table-button" (click)="edit(element)"
                    matTooltip="{{'policies.list.table.tooltips.edit' | translate}}">
              <mat-icon>edit</mat-icon>
            </button>

          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="delete">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">

            <button mat-button mat-icon-button class="table-button" (click)="confirmDelete(element)"
                    [disabled]="element.retentionUsed"
                    matTooltip="{{'policies.list.table.tooltips.delete' | translate}}">
              <mat-icon>delete</mat-icon>
            </button>

          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns()"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns();"></mat-row>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">{{"dataNotFound" | translate}}</td>
        </tr>
      </table>
    </section>
    <section [hidden]="(loading$ | async) || superAdmin === undefined">
      <mat-paginator [length]="retentionItemsLength" [pageSize]="getPageSize()" [showFirstLastButtons]="false" [pageSizeOptions]="[10,20,30]"></mat-paginator>
    </section>
  </mat-card>
</section>
