export interface AuditLog {
  uuid: string;
  eventType: string;
  eventDate: number;
  library: string;
  documentClass: string;
  document: string;
  author: string;
  errorCode: string;
  message: string;
  logLevel: string;
}

export interface AuditLogFilterTypes {
  auditLogFilterTypeValues: string [],
}

export class AuditLogFilterValues {
  eventType: string;
  date: string;
  author: string;
  libraryId: string;
  classId: string;
  documentId: string;
  logLevel: string;
  startDate: string;
  endDate: string;
}
