import {Component, OnDestroy, OnInit} from '@angular/core';
import {LibraryDetails} from '../../../models/library.model';
import {LibraryService} from '../../../services/library.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogService} from '../../../services/dialog.service';
import {MatDialog} from '@angular/material/dialog';
import {PolicyAssignerComponent} from '../policy-assigner/policy-assigner.component';
import {BehaviorSubject, Subject} from 'rxjs';
import {UnbindRetentionsCommand} from '../../../models/retention.model';
import {JobService} from '../../../services/job.service';
import {TranslateService} from '@ngx-translate/core';
import {CustomSnackBarService} from '../../../services/custom-snackbar.service';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-libraries-detail',
  templateUrl: './libraries-detail.component.html',
  styleUrls: ['./libraries-detail.component.scss']
})
export class LibrariesDetailComponent implements OnInit, OnDestroy {
  public details: LibraryDetails;
  public recalculateSubject = new Subject<boolean>();
  public openAssignerSubject = new Subject<boolean>();
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  private globalLoadingSubject = new BehaviorSubject<boolean>(false);
  public globalLoading$ = this.globalLoadingSubject.asObservable();
  public reloadAssignedRetentionsTableSubject = new Subject<boolean>();

  constructor(private libraryService: LibraryService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private userService: UserService,
              private dialogService: DialogService,
              private jobService: JobService,
              private dialog: MatDialog,
              private customSnackBarService: CustomSnackBarService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    const libraryId = this.activatedRoute.snapshot.params.id;
    this.get(libraryId);
    this.initSubjects();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  get(libraryId: string): void {
    if (libraryId) {
      this.loadingSubject.next(true);
      this.libraryService.details(libraryId).subscribe({
        next: (resp) => {
          this.loadingSubject.next(false);
          this.globalLoadingSubject.next(false);
          this.setData(resp);
        },
        error: (error) => {
          this.loadingSubject.next(false);
          this.globalLoadingSubject.next(false);
          this.dialogService.error(error?.error?.error?.message ?? error.message);
        }
      });
    } else {
      const message = this.translate.instant('dialog.noLibrary');
      const dialogTitle = this.translate.instant('dialog.warning');
      this.dialogService.error(message, dialogTitle);
      this.router.navigate(['libraries/active']);
    }
  }

  setData(details: LibraryDetails): void {
    this.details = details;
    if (this.details.retentionBindings) {
      this.details.retentionBindings.forEach(binding => {
        const parts: string [] = binding.retentionId.split('@');
        binding.retentionDisplayName = parts[0] + ' - ' + parts[1];
      });
    }
  }

  recalculate(): void {
    this.loadingSubject.next(true);
    this.libraryService.recalculate(this.details.libraryId).subscribe({
      next: (resp) => {
        this.loadingSubject.next(false);
        resp.retentionBindings = this.details.retentionBindings;
        resp.hasRootFolderRetention = this.details.hasRootFolderRetention;
        this.setData(resp);
      },
      error: (error) => {
        this.loadingSubject.next(false);
        this.dialogService.error(error?.error?.error?.message ?? error.message);
      }
    });
  }

  openPolicyAssigner(): void {
    this.dialog.open(PolicyAssignerComponent, {
      data: {libraryDetails: this.details},
      width: '600px'
    }).afterClosed().subscribe((choice) => {
      if (choice) {
        this.get(this.details.libraryId);
      }
    });
  }

  confirmUnbindAll(): void {
    const unbindMessage = this.translate.instant('dialog.unbindAll', {library: this.details.displayName});
    const dialogTitle = this.translate.instant('dialog.confirmation');
    this.dialogService.plain(unbindMessage, dialogTitle)
      .afterClosed().subscribe((choice) => {
      if (choice) {
        this.unbindAll();
      }
    });
  }


  unbindAll(): void {
    this.loadingSubject.next(true);
    const unbindRetentionCommand: UnbindRetentionsCommand = {existingBindingTimestamp: new Date().getTime().toString()};
    this.libraryService.unbindAll(this.details.libraryId, unbindRetentionCommand).subscribe({
      next: (resp) => {
        this.loadingSubject.next(false);
        this.get(this.details.libraryId);
      },
      error: (error) => {
        this.loadingSubject.next(false);
        this.dialogService.error(error?.error?.error?.message ?? error.message);
      }
    });
  }

  recalculateRetentionProperties(): void {
    this.globalLoadingSubject.next(true);
    this.jobService.recalculateRetentions(this.details.libraryId, this.details.libraryType).subscribe({
      next: () => {
        this.customSnackBarService.openSuccessSnackbar(this.translate.instant('libraries.edit.folders.recalculateSnackbarMessage'));
        this.get(this.details.libraryId);
      },
      error: (error) => {
        this.globalLoadingSubject.next(false);
        this.dialogService.error(error?.error?.error?.message ?? error.message);
      }
    });
  }

  back(): void {
    const sub = this.isAllCriteriaMet() ? 'active' : 'pending';
    const to = 'libraries/' + sub;
    this.router.navigate([to]);
  }

  isNotYetCalculated(): boolean {
    return typeof this.details.active === 'undefined';
  }

  isAllCriteriaMet(): boolean {
    return !this.isNotYetCalculated() && !!this.details && typeof this.details.activationThreshold === 'undefined';
  }

  isReady(): boolean {
    return !this.isNotYetCalculated() && !!this.details && typeof this.details.retentionBindings === 'undefined'
      && (this.onlyRootFolderNotAssigned() || typeof this.details.activationThreshold === 'undefined');
  }

  isWithErrors(): boolean {
    return !this.isNotYetCalculated() && !!this.details && (!this.isReady() && !!this.details.retentionBindings);
  }

  isError(): boolean {
    return !this.isNotYetCalculated() && !this.isAllCriteriaMet() && !this.isReady() && !this.isWithoutPolicyAtRoot();
  }

  isWithoutPolicyAtRoot(): boolean {
    return !this.details.hasRootFolderRetention && !!this.details.retentionBindings && this.details.retentionBindings.length > 0;
  }

  onlyRootFolderNotAssigned(): boolean {
    return !!this.details.activationThreshold && this.details.activationThreshold.length === 1 && this.details.activationThreshold[0] === 'The root folder has no retention assigned.';
  }

  isSuperAdmin(): boolean {
    return this.userService.isSuperAdmin();
  }

  gotoLibrarySettingsUrl(): void {
    const url = this.libraryService.getLibrarySettingsUrl(this.details.libraryId);
    window.open(url, '_blank');
  }

  initSubjects(): void {
    this.initRecalculateSubject();
    this.initOpenAssignerSubject();
    this.initReloadAssignedRetentionsTableSubject();
  }

  unsubscribe(): void {
    this.recalculateSubject.unsubscribe();
    this.openAssignerSubject.unsubscribe();
    this.reloadAssignedRetentionsTableSubject.unsubscribe();
  }

  initRecalculateSubject(): void {
    this.recalculateSubject.subscribe(() => this.recalculate());
  }

  initOpenAssignerSubject(): void {
    this.openAssignerSubject.subscribe(() => this.openPolicyAssigner());
  }

  initReloadAssignedRetentionsTableSubject(): void {
    this.reloadAssignedRetentionsTableSubject.subscribe(() => this.get(this.details.libraryId));
  }

}
